import React from 'react'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { TradeRecordListItemType } from '../../api/swap/klineData'
import { shortenAddress } from '../../utils'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { $fitDigitsNum } from '../../utils/common'

const QuotesTradeListItemRow = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.primary5};
  }
`
const QuotesTradeListItemRowItem = styled.a<{
  myActive?: boolean
  textAlign?: string
}>`
  height: 100%;
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 12px;
  display: block;
  flex: 1;
  text-decoration: none;
  color: ${({ theme, myActive }) => {
    if (typeof myActive === 'undefined') {
      return theme.text1
    }
    return myActive ? theme.green1 : theme.red1
  }};
  text-align: ${({ textAlign }) => {
    if (typeof textAlign === 'undefined') {
      return 'left'
    }
    return textAlign
  }};
`

interface QuotesListCompeontProps {
  items: TradeRecordListItemType[]
  loadMore: (startIndex: number, stopIndex: number) => Promise<void> | void
  hasNextPage: boolean
}

const QuotesListComponent = ({ items, loadMore, hasNextPage }: QuotesListCompeontProps) => {
  const { t } = useTranslation()
  const Row = ({ index, style }: { data: TradeRecordListItemType[]; index: number; style: any }) => {
    if (!items[index]) {
      return <></>
    }
    const itemLoading = index === items.length
    if (itemLoading) {
      const content = 'Loading...'
      return (
        <div style={style} key={index}>
          {content}
        </div>
      )
    }
    // eslint-disable-next-line @typescript-eslint/camelcase
    const { create_time, type, price, volume, receipt_to, hash }: TradeRecordListItemType = items[index]
    const isBoolean = Boolean(type === '1')
    const formatPrice = $fitDigitsNum(price, 4)
    const formatVolume = $fitDigitsNum(volume, 4)
    return (
      <QuotesTradeListItemRow key={items[index].block} style={style}>
        {/* eslint-disable-next-line @typescript-eslint/camelcase*/}
        <QuotesTradeListItemRowItem href={'https://www.onemorepay.top/tx/' + hash} textAlign={'left'}>
          {/* eslint-disable-next-line @typescript-eslint/camelcase */}
          {create_time}
        </QuotesTradeListItemRowItem>
        <QuotesTradeListItemRowItem
          href={'https://www.onemorepay.top/tx/' + hash}
          textAlign={'center'}
          myActive={isBoolean}
        >
          {type === '1' ? t('Buy') : t('Sell')}
        </QuotesTradeListItemRowItem>
        <QuotesTradeListItemRowItem
          href={'https://www.onemorepay.top/tx/' + hash}
          textAlign={'center'}
          myActive={isBoolean}
        >
          {formatPrice}
        </QuotesTradeListItemRowItem>
        <QuotesTradeListItemRowItem
          href={'https://www.onemorepay.top/tx/' + hash}
          textAlign={'center'}
          myActive={isBoolean}
        >
          {formatVolume}
        </QuotesTradeListItemRowItem>
        {/* eslint-disable-next-line @typescript-eslint/camelcase*/}
        <QuotesTradeListItemRowItem href={'https://www.onemorepay.top/address/' + receipt_to} textAlign={'right'}>
          {shortenAddress(receipt_to, 2)}
        </QuotesTradeListItemRowItem>
      </QuotesTradeListItemRow>
    )
  }

  const itemCount = hasNextPage ? items.length + 20 : items.length

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        // Use these actual sizes to calculate your percentage based sizes
        <InfiniteLoader isItemLoaded={index => index < items.length} itemCount={itemCount} loadMoreItems={loadMore}>
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              height={500}
              width={width}
              itemCount={itemCount}
              itemSize={40}
              onItemsRendered={onItemsRendered}
              ref={ref}
              overscanCount={20}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  )
}

export default QuotesListComponent
