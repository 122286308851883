import React, { forwardRef, useContext, useImperativeHandle, useLayoutEffect, useRef } from 'react'
import { Context } from './LineChartContainer'
import { IChartApi, ISeriesApi } from 'lightweight-charts'
const KChartLineSeries = forwardRef((props: any, ref) => {
  const parent = useContext(Context) as { _api: IChartApi; api: () => IChartApi; free: () => void }
  const context = useRef<{
    _api?: ISeriesApi<'Line'>
    api: () => ISeriesApi<'Line'>
    free: () => void
    _parent?: IChartApi
  }>({
    api() {
      if (!this._api) {
        const { children, priceFormat, autoscaleInfoProvider, color, ...rest } = props
        this._api = parent.api().addLineSeries({
          color,
          lineWidth: 2,
          lastValueVisible: false,
          priceLineVisible: false,
          crosshairMarkerVisible: false,
          crosshairMarkerRadius: false,
          priceFormat:
            priceFormat && typeof priceFormat === 'object'
              ? priceFormat
              : {
                  type: 'price',
                  precision: 3,
                  minMove: 0.001
                },
          autoscaleInfoProvider:
            autoscaleInfoProvider && typeof autoscaleInfoProvider === 'function'
              ? autoscaleInfoProvider
              : (original: any) => {
                  const res = original()
                  if (res !== null) {
                    res.priceRange.minValue -= 0.001
                    res.priceRange.maxValue += 0.001
                  }
                  return res
                },
          ...rest
        })
      }
      return this._api
    },
    free() {
      if (this._api) {
        parent && parent.free()
      }
    }
  })
  useLayoutEffect(() => {
    const currentRef = context.current
    const { children, ...rest } = props
    currentRef.api().applyOptions(rest)
  })
  useImperativeHandle(ref, () => context.current.api(), [])

  return <Context.Provider value={context.current}>{props.children}</Context.Provider>
})
export default KChartLineSeries
