import React from 'react'
import styled from 'styled-components'
import { Row, RowBetween } from '../../components/Column'
import ALYXIcon from '../../assets/svg/ALYX.svg'
import DiscordIcon from '../../assets/svg/discord.svg'
import TwitterIcon from '../../assets/svg/twitter.svg'
import TelegramIcon from '../../assets/svg/tg.svg'
import CoinIcon from '../../assets/svg/footer-coin.svg'
import InsIcon from '../../assets/svg/ins.svg'
import FooterALYXIcon from '../../assets/svg/footer-alyx.svg'
import { ButtonPrimary } from '../../components/Button'
import { useResponsive } from 'ahooks'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getALYXPrice } from '../../utils'

const FooterContainer = styled.div<{ isXs: boolean }>`
  width: 100%;
  background: #000;
  padding: ${({ isXs }) => (isXs ? '24px 0 10px 0' : '48px 0')};
  color: #fff;
  font-size: 14px;
  font-weight: 500;
`
const FooterConent = styled.div<{ isXs: boolean }>`
  max-width: ${({ isXs }) => (isXs ? '90%' : '1120px')};
  margin: 0 auto;
`
const Footer = () => {
  const responsive = useResponsive()
  const isXs = responsive.xs && !responsive.md
  const { t } = useTranslation()
  const history = useHistory()
  const [ALYXPrice, setALYXPrice] = React.useState<any>()

  const listIcon = [
    {
      icon: DiscordIcon,
      link: 'https://alyx.io/'
    },
    {
      icon: TwitterIcon,
      link: 'https://alyx.io/'
    },
    {
      icon: InsIcon,
      link: 'https://alyx.io/'
    },
    {
      icon: TelegramIcon,
      link: 'https://alyx.io/'
    },
    {
      icon: CoinIcon,
      link: 'https://alyx.io/'
    }
  ]

  React.useEffect(() => {
    getALYXPrice().then(res => setALYXPrice(res))
  }, [])

  return (
    <FooterContainer isXs={isXs}>
      <FooterConent isXs={isXs}>
        {isXs ? (
          <>
            <Row>
              <img src={ALYXIcon} />
              <p style={{ paddingLeft: 10 }}>ALYX Swap</p>
            </Row>
            <Row style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <a style={{ paddingTop: 10 }}>Cookies</a>
              <a style={{ paddingTop: 10 }}>Privacy agreement</a>
              <a style={{ paddingTop: 10 }}>Service</a>
            </Row>
          </>
        ) : (
          <RowBetween style={{ borderBottom: '2px solid rgba(255,255,255,0.1)', paddingBottom: 20 }}>
            <Row>
              <img src={ALYXIcon} />
              <p style={{ paddingLeft: 10 }}>ALYX Swap</p>
            </Row>
            <Row>
              <a style={{ paddingRight: 20 }}>Cookies</a>
              <a style={{ paddingRight: 20 }}>Privacy agreement</a>
              <a>Service</a>
            </Row>
          </RowBetween>
        )}
        {isXs && (
          <RowBetween
            style={{
              borderBottom: '1px solid rgba(255,255,255,0.1)',
              borderTop: '1px solid rgba(255,255,255,0.1)',
              padding: '20px 0',
              marginTop: 20
            }}
          >
            <Row>
              <img src={FooterALYXIcon} />
              <p style={{ padding: '0 10px' }}>ALYX Price ≈ ${ALYXPrice}</p>
            </Row>
            <ButtonPrimary style={{ width: 128, height: 32 }}>{t('Buy ALYX')}</ButtonPrimary>
          </RowBetween>
        )}
        {isXs && (
          <Row style={{ paddingTop: 10, flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{ fontWeight: 500, fontSize: 16 }}>Contact us</div>
            <div style={{ fontWeight: 400, fontSize: 12, paddingTop: 10 }}>service@alyx.com</div>
          </Row>
        )}
        {isXs && (
          <Row style={{ paddingTop: 20 }}>
            {listIcon.map((item, index) => {
              return <img src={item.icon} style={{ marginRight: 10, width: 32 }} key={index} />
            })}
          </Row>
        )}
        {isXs && (
          <Row style={{ paddingTop: 10, justifyContent: 'center' }}>
            <div style={{ fontWeight: 400, fontSize: 12, paddingTop: 40 }}>Alyx © 2021, All rights reserved</div>
          </Row>
        )}
        {!isXs && (
          <RowBetween style={{ paddingTop: 10 }}>
            <p style={{ fontWeight: 400, fontSize: 12 }}>Alyx © 2021, All rights reserved</p>
            <p style={{ fontWeight: 400, fontSize: 12 }}>Contact us : service@alyx.com</p>
          </RowBetween>
        )}
        {!isXs && (
          <RowBetween style={{ paddingTop: 10 }}>
            <Row>
              {listIcon.map((item, index) => {
                return <img src={item.icon} style={{ marginRight: 10, width: 32 }} key={index} />
              })}
            </Row>
            <Row>
              <Row>
                <img src={FooterALYXIcon} />
                <p style={{ padding: '0 10px' }}>
                  ALYX {t('Price')} ≈ ${ALYXPrice}
                </p>
              </Row>
              <ButtonPrimary
                onClick={() => {
                  history.push('/swap')
                }}
                style={{ width: 128, height: 32 }}
              >
                {t('Buy ALYX')}
              </ButtonPrimary>
            </Row>
          </RowBetween>
        )}
      </FooterConent>
    </FooterContainer>
  )
}

export default Footer
