import { useEffect, useReducer, useState } from 'react'
const fetchDataReducer = (state: any, action: { type: any; payload?: any }) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isErroe: false,
        data: action.payload
      }
    case 'FETCH_ERROR':
      return {
        ...state,
        isLoading: false,
        isErroe: false,
        data: action.payload
      }
    default:
      return state
  }
}

const useDataApi = ({
  requestFunction,
  params,
  initData
}: {
  requestFunction: Function
  params: any
  initData: any
}) => {
  const [newParams, setParams] = useState(params)
  const [state, dispatch] = useReducer(fetchDataReducer, {
    data: initData,
    isLoading: false,
    isError: false
  })
  // 副作用
  useEffect(() => {
    let doCancel = false
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' })
      try {
        const result = await requestFunction(newParams)
        if (!doCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: result })
        }
      } catch (error) {
        dispatch({ type: 'FETCH_ERROR' })
      }
    }
    fetchData()
    return () => {
      doCancel = true
    }
  }, [newParams, requestFunction])

  return [state, setParams]
}
export default useDataApi
