import DoubleCurrencyLogo from '../DoubleLogo'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Currency } from '@uniswap/sdk'
import { useDefaultChartData } from '../KLineChart/hook2'
import KLineChart from '../KLineChart'
import { CoinExType } from '../../api/swap/klineData'
import { useTranslation } from 'react-i18next'
import KlineChart2Tools from '../KLineChart/KlineChart2Tools'
import More from '../../assets/images/more.svg'
import DarkMore from '../../assets/images/dark-more.svg'
import { useDarkModeManager } from '../../state/user/hooks'
import { isMobile } from 'react-device-detect'
import { useQuotesActionHandlers } from '../../state/quotes/hook'
const QuotesFlexWrapKChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  padding: 10px 16px 5px;
`
const QuotesFlexWrapCoinContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px 0 15px;
`
const QuotesFlexWrapCoinName = styled.div<{ margin: boolean; sizeraw: number }>`
  margin-left: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};
`
const QuotesFlexWrapCoinRateContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: relative;
`
const MoreDiv = styled.img<{ width: string; height: string }>`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: 50%;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  transform: translateY(-50%);
  cursor: pointer;
  // &:active {
  //   background-color: ${({ theme }) => theme.bg5};
  // }
`
const QuotesFlexWrapCoinRateContainerLeft = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  h2 {
    margin: 0 15px 0 0;
    font-size: 34px;
    color: ${({ theme }) => theme.text1};
    padding: 10px 0;
  }

  small {
    font-size: 18px;
  }

  p {
    font-size: 22px;
    font-weight: bold;
    margin: 0 15px 0 0;
  }

  span {
    font-weight: bold;
    font-size: 22px;
    color: ${({ theme, isActive }) => (isActive ? theme.green2 : theme.red3)};
  }

  @media (max-width: 1100px) {
    flex-wrap: wrap;
    h2 {
      font-size: 30px;
      margin-right: 0;
      padding-top: 0;
    }

    p {
      font-size: 12px;
    }
    small {
      font-size: 15px;
      font-weight: bold;
    }

    span {
      font-size: 12px;
    }
  }
`
const QuotesFlexWrapCoinRateContainerRight = styled.div`
  display: flex;
  align-items: baseline;
  max-width: 260px;
  flex-wrap: wrap;
  @media (max-width: 721px) {
    flex-shrink: 0;
    width: 50%;
  }
  .switcher {
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 8px;
    color: #2196f3;
  }

  .switcher-item {
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    padding: 6px 8px;
    font-size: 14px;
    color: #262b3e;
    background-color: transparent;
    margin-right: 8px;
    border: none;
    outline: none;
  }

  .switcher-item:hover {
    background-color: #f2f3f5;
  }

  .switcher-active-item {
    text-decoration: none;
    cursor: default;
    color: #262b3e;
  }

  .switcher-active-item,
  .switcher-active-item:hover {
    background-color: #e1eff9;
  }
`

const QuotesFlexWrapLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex: 1;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  width: 100%;
`

const CoinInfoItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%;
  box-sizing: border-box;
  margin-bottom: 10px;
  @media (max-width: 721px) {
    margin-bottom: 3px;
  }
`
const CoinInfoLabel = styled.span`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text6};
  @media (max-width: 721px) {
    font-size: 10px;
    i {
      display: none;
    }
  }
`
const CoinInfoValue = styled.span`
  display: flex;
  font-size: 16px;
  padding-top: 10px;
  font-weight: bold;
  @media (max-width: 721px) {
    font-size: 14px;
    padding-top: 3px;
  }
`
const KLineBlock = styled.div`
  position: relative;
  width: 100%;
  @keyframes fadeInDown {
    0% {
      -webkit-transform: translate3d(0, -20%, 0);
      -webkit-transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0);
      opacity: 0;
    }
    100% {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeInDown {
    0% {
      -webkit-transform: translate3d(0, -20%, 0);
      opacity: 0;
    }
    100% {
      -webkit-transform: none;
      opacity: 1;
    }
  }
`
const MaskDiv = styled.div<{ isActive?: boolean; isMobile: boolean }>`
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
  height: ${({ isMobile }) => (!isMobile ? '100%' : '100vh')};
  width: 100%;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`

const CoinInfoIndcate = styled.div<{ isActive?: boolean }>`
  background: ${({ theme }) => theme.bg1};
  width: 100%;
  display: none;
  position: absolute;
  z-index: 101;
  top: 0;
  padding: 10px 16px 5px;
  box-shadow: ${({ theme }) => theme.boxColor1};
  transition: all 0.3s ease-in;

  ${({ isActive }) => {
    if (!isActive) {
      return ''
    }
    return `    display: block;
    -webkit-animation: fadeInDown 0.3s;
    animation: fadeInDown 0.3s;`
  }}
`

export interface QuotesFlexWrapProps {
  children?: React.ReactNode
  token: Currency
  tradeToken: Currency
  coinInfo?: CoinExType
}
export default function QuotesFlex({ token, tradeToken }: QuotesFlexWrapProps) {
  const { t } = useTranslation()
  const [showIndicate, setShowIndicate] = useState<boolean>(false)
  const { setKLineTime, chartData, kLineTimeState, coinInfo, updateData } = useDefaultChartData({
    token,
    tradeToken
  })
  const KlineChart2ToolsRef = useRef<any>(null)
  const [darkMode] = useDarkModeManager()
  const { onChangeZIndex } = useQuotesActionHandlers()
  return (
    <QuotesFlexWrapLeftContent>
      <QuotesFlexWrapKChartContainer>
        <QuotesFlexWrapCoinContainer>
          <DoubleCurrencyLogo currency0={token} currency1={tradeToken} size={24} margin={false} />
          <QuotesFlexWrapCoinName margin={true} sizeraw={5}>
            {token && token.symbol}
            {tradeToken && tradeToken.symbol ? '/' + tradeToken.symbol : ''}
          </QuotesFlexWrapCoinName>
        </QuotesFlexWrapCoinContainer>
        <QuotesFlexWrapCoinRateContainer>
          <QuotesFlexWrapCoinRateContainerLeft isActive={coinInfo.isTrue}>
            <h2>
              <small>$</small>
              {coinInfo.price}
            </h2>
            <span>
              {coinInfo.isTrue ? '+' : ''}
              {coinInfo.price_24h_change}%
            </span>
          </QuotesFlexWrapCoinRateContainerLeft>
          <QuotesFlexWrapCoinRateContainerRight>
            <CoinInfoItem>
              <CoinInfoLabel>{t('24h最高价')}</CoinInfoLabel>
              <CoinInfoValue>{coinInfo.usd_24h_max}</CoinInfoValue>
            </CoinInfoItem>
            <CoinInfoItem>
              <CoinInfoLabel>
                {t('24h交易次数')}
                {/*<i>({token.symbol})</i>*/}
              </CoinInfoLabel>
              <CoinInfoValue>{coinInfo.price_24h_vol_count}</CoinInfoValue>
            </CoinInfoItem>
            <CoinInfoItem>
              <CoinInfoLabel>{t('24h最低价')}</CoinInfoLabel>
              <CoinInfoValue>{coinInfo.usd_24h_min}</CoinInfoValue>
            </CoinInfoItem>
            {/*<CoinInfoItem>*/}
            {/*  <CoinInfoLabel>*/}
            {/*    {t('24h成交额')}*/}
            {/*    <i>({tradeToken.symbol})</i>*/}
            {/*  </CoinInfoLabel>*/}
            {/*  <CoinInfoValue>{coin24ChangePrice}</CoinInfoValue>*/}
            {/*</CoinInfoItem>*/}
            <CoinInfoItem>
              <CoinInfoLabel>{t('持有地址数')}</CoinInfoLabel>
              <CoinInfoValue>{coinInfo.holder}</CoinInfoValue>
            </CoinInfoItem>
          </QuotesFlexWrapCoinRateContainerRight>
          <MoreDiv
            onClick={() => {
              if (isMobile) {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
                document.body.style.overflow = !showIndicate ? 'hidden' : 'auto'
                const currentOverFlow = document.body.style.overflow
                onChangeZIndex(currentOverFlow === 'hidden' ? 69 : 70)
              }
              KlineChart2ToolsRef.current.setIsSelected(false)
              setShowIndicate(!showIndicate)
            }}
            src={darkMode ? More : DarkMore}
            width={'15px'}
            height={'90px'}
          ></MoreDiv>
        </QuotesFlexWrapCoinRateContainer>
      </QuotesFlexWrapKChartContainer>

      <KLineBlock>
        <MaskDiv
          isMobile={isMobile}
          isActive={showIndicate}
          onClick={() => {
            if (isMobile) {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
              document.body.style.overflow = !showIndicate ? 'hidden' : 'auto'
              const currentOverFlow = document.body.style.overflow
              onChangeZIndex(currentOverFlow === 'hidden' ? 69 : 70)
            }
            setShowIndicate(!showIndicate)
          }}
        ></MaskDiv>
        <CoinInfoIndcate isActive={showIndicate}>
          <CoinInfoItem>
            <CoinInfoLabel>{t('24小时交易额')}</CoinInfoLabel>
            <CoinInfoValue>{coinInfo.usd_24h_vol}</CoinInfoValue>
          </CoinInfoItem>
          <CoinInfoItem>
            <CoinInfoLabel>{t('流通市值')}</CoinInfoLabel>
            <CoinInfoValue>{coinInfo.usd_market_value}</CoinInfoValue>
          </CoinInfoItem>
          <CoinInfoItem>
            <CoinInfoLabel>{t('池子价值')}</CoinInfoLabel>
            <CoinInfoValue>{coinInfo.usd_pool_value}</CoinInfoValue>
          </CoinInfoItem>
        </CoinInfoIndcate>
        <KlineChart2Tools ref={KlineChart2ToolsRef} active={kLineTimeState} toggle={setKLineTime}>
          <KLineChart updateData={updateData} data={chartData}></KLineChart>
        </KlineChart2Tools>
      </KLineBlock>
    </QuotesFlexWrapLeftContent>
  )
}
