import { createReducer } from '@reduxjs/toolkit'
import { setZIndex } from './actions'

export interface QuotesState {
  readonly zIndex: number
  // the typed recipient address or ENS name, or null if swap should go to sender
}

const initialState: QuotesState = {
  zIndex: 70
}

export default createReducer<QuotesState>(initialState, builder =>
  builder.addCase(setZIndex, (state, { payload: { zIndex } }) => {
    state.zIndex = zIndex
  })
)
