import React from 'react'
import { QuotesFlexWrapProps } from './QuotesFlex'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LineChart from '../LineChart'
import { useQuotesDataViewHook } from './hook/QuotesTradeListHook'

const QuotesDataContainer = styled.div`
  display: block;
  padding: 7px 0 15px;
  background-color: ${({ theme }) => theme.bg1};
  height: 100%;
`
const QuotesDataTitle = styled.p`
  margin: 0;
  padding: 7px 15px;
  font-size: 13px;
`

export default function QuotesDataView({ token, tradeToken }: QuotesFlexWrapProps) {
  const { t } = useTranslation()
  const { data } = useQuotesDataViewHook({ token, tradeToken })
  return (
    <>
      <QuotesDataContainer>
        <QuotesDataTitle>{t('24h 交易地址数')}</QuotesDataTitle>
        <LineChart data={data}></LineChart>
      </QuotesDataContainer>
    </>
  )
}
