import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NavHeader = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`
const NavItem = styled.div<{ active: boolean }>`
  display: flex;
  color: #141416;
  font-size: 14px;
  margin-left: 40px;
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    margin-left: -2px;
    display: block;
    width: 4px;
    height: 4px;
    background: ${({ active }) => (active ? '#0100fc' : '#fff')};
    transition: width 0.3s;
  }
`
const HeaderNav = () => {
  const history = useHistory()
  const { t } = useTranslation()

  // 获取路径history.location.pathname
  const path = window.location.hash

  // 选中的nav
  const [activeNav, setActiveNav] = useState('#/')
  const listnav = [
    {
      id: 1,
      title: 'Home',
      path: '/',
      key: '#/'
    },
    {
      id: 2,
      title: 'Swap',
      path: '/swap',
      key: '#/swap'
    },
    {
      id: 3,
      title: 'Liquidity',
      path: '/pool',
      key: '#/pool'
    }
  ]

  useEffect(() => {
    setActiveNav(path)
  }, [path])

  return (
    <NavHeader>
      {listnav.map(item => {
        return (
          <NavItem
            style={{
              color: activeNav === `${item.key}` ? '#0100FC' : '#141416',
              fontWeight: activeNav === `${item.key}` ? 700 : 'normal'
            }}
            key={item.id}
            onClick={() => {
              setActiveNav(item.key)
              history.push(item.path)
            }}
            active={activeNav === item.key}
          >
            {t(item.title)}
          </NavItem>
        )
      })}
    </NavHeader>
  )
}

export default HeaderNav
