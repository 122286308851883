import { Currency, ETHER, Token } from '@uniswap/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import EthereumLogo from '../../assets/images/BNB.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import USDTLogo from '../../assets/images/token/0x55d398326f99059fF775485246999027B3197955.png'
import ALYXLogo from '../../assets/images/token/alyx.png'
import SLOTLogo from '../../assets/images/token/slot.png'
import AUSDLogo from '../../assets/images/token/ausd.png'

// const getTokenLogoURL = (address: string) => `https://image.selltoken.org/${address}.png`
// const getTokenLogoURL = (address: string | undefined) =>
// address !== 'USDT'
//     ? `https://www.alyxscan.com/images/favicon-32x32.png`
//     : 'https://static.aelfunion.com/elfu/static/elf1699337479574134539.png'
const getTokenLogoURL = (address: string) => {
  switch (address) {
    case '0x311C229767c3dcee6fCc84946587A15d96e51Cd5':
      return USDTLogo
    case '0xdf9885F180A2913947f8D8b1443494E33110CfB5':
      return ''
    case '0x28D3483680eb8D364f1a2e486d670176D7bC2f99':
      return AUSDLogo
    case '0x7A747F249131544175A814DE7a5715F171835771':
      return SLOTLogo
    case '0x6Cc2cD3CaA62b9b4f4BCB462404e7D8968377bd4':
      return ALYXLogo
  }
  return ''
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }

      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])
  if (currency === ETHER) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }
  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
