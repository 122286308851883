// import qs from 'qs'
import { callbackTimer } from '../../components/KLineChart/KlineChart2Tools'
import { PageTypeParams } from '../common'
import { ChainId } from '@uniswap/sdk'

export interface KlineDataApiDataType {
  created_time: number
  open: number
  high: number
  low: number
  close: number
  volume: number
  market_date: string
}

export const fetchBaseUrl = process.env.REACT_APP_BASE_URL
//  获取 K线数据
export function getKLineData(params: {
  token: string
  tradeToken: string
  swapVersion: string
  type: string
  size?: string
}): Promise<KlineDataApiDataType[]> {
  return fetch(fetchBaseUrl + '/api/market/getMarketData', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase(),
      type: callbackTimer(params.type),
      size: params.size! || '600'
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export interface ResponseIncreaseDataType {
  increase: string //涨幅 前端加%
  price: string //价格
  diff: string // 差价
}
// 获取涨幅数据
export function getIncreaseData(params: {
  token: string
  tradeToken: string
  swapVersion: string
}): Promise<ResponseIncreaseDataType> {
  return fetch(fetchBaseUrl + '/api/market/amountOfIncrease', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase()
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
export interface ListResultType<T> {
  total: number
  per_page: number
  current_page: number
  last_page: number
  data: T
}
export interface TradeRecordListItemType {
  price: string //价格
  block: number //区块高度
  amount1_in: string
  amount1_out: string
  create_time: string //时间
  type: string //1 是买 2 是卖
  volume: string //交易量
  receipt_to: string
  hash: string
}
export interface TradeRecordListParams extends PageTypeParams {
  token: string
  tradeToken: string
  swapVersion: string
}
export function getTradeRecordList(params: TradeRecordListParams): Promise<ListResultType<TradeRecordListItemType[]>> {
  return fetch(fetchBaseUrl + '/api/market/tradeRecord', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase(),
      page: params.page,
      size: params.size
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
//  获取24
export interface CoinExType {
  // token 价格
  price: string
  // 流动池中的数量
  reserves0: string
  // 流动池中 token1 的数量
  reserves1: string
  // 价格 金
  usd: number
  // 24h最高价
  usd_24h_max: number | string
  // 24h最低价
  usd_24h_min: number | string
  // 24交易量
  usd_24h_vol: number | string
  // token 持有地址数
  holder: number | string
  // 池子价格
  usd_pool_value: number | string
  // 流动市值
  usd_market_value: number | string
  // 发放量
  supply: number | string
  //token0价格(金本位)
  //流动池中token1的数里
  price_24h_change: number | string
  //24h涨跌幅"
  price_24h_max: number | string //24h最高价(币本位
  price_24h_min: number | string
  //24h最低价(市本位)
  price_24h_vol: number | string //24h交易量(币本位)
  price_24h_vol_count: number | string //24h交易次数
  //24h交易额(金本位)
  isTrue?: boolean
}

export function getCoinExTypeData(params: { token: string; tradeToken: string }): Promise<CoinExType> {
  return fetch(
    'http://onemorewallet-market.onemoredao.com/system/getMarketInfo?token0=' +
      params.token +
      '&token1=' +
      params.tradeToken +
      '&version=' +
      1,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === '200') {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export function getLatestMarketOne(params: {
  token: string
  tradeToken: string
  swapVersion: string
  type: string
  size?: string
}): Promise<KlineDataApiDataType> {
  return fetch(fetchBaseUrl + '/api/market/latestMarket', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase(),
      type: callbackTimer(params.type)
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
  //   /api/market/latestMarket
}

export interface CoinInfoDetailType {
  id: number
  contract: string //币种合约地址
  name: 'string' //币种名称
  intro: string //币种简介  （富文本）
  tags_data: string[]
  medium_data: {
    logo: string
    url: string
  }[]
}

// market/coinInfo
export function getCoinInfoDetail(params: { token: string; lang?: string }): Promise<CoinInfoDetailType> {
  return fetch(fetchBaseUrl + '/api/market/coinInfo', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      lang: params.lang || 'zh-cn'
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
  //   /api/market/latestMarket
}

export interface CoinObjectInfoDetail extends CoinInfoDetailType, CoinExType {}

export function judgeTokenHasMarketData(params: {
  token: string
  tradeToken: string
  swapVersion: string
  chainId?: ChainId
}): Promise<number> {
  return fetch(fetchBaseUrl + '/api/market/isMarketData', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      chain_id: params.chainId
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data.is_market_data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export interface LatestTradeAddressListItem {
  buy_num: number //买数量
  sell_num: number //卖数量
  date_time: string //时间
  time: number | string //时间戳
}
export function getLatestTradeAddressList(params: {
  token: string
  tradeToken: string
  swapVersion: string
}): Promise<LatestTradeAddressListItem[]> {
  return fetch(fetchBaseUrl + '/api/market/latestTradeAddress', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase()
      // eslint-disable-next-line @typescript-eslint/camelcase
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
