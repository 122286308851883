import { Trade, TradeType } from '@uniswap/sdk'
import React, { useContext, useMemo } from 'react'
import {  AlertTriangle } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { TYPE } from '../../theme'
import { ButtonPrimary } from '../Button'
import { isAddress, shortenAddress } from '../../utils'
import { computeSlippageAdjustedAmounts, 
  // computeTradePriceBreakdown, warningSeverity 
} from '../../utils/prices'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'
import ExchangeIcon from '../../assets/svg/exchange.svg'
import { useTranslation } from 'react-i18next'


export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges
}: {
  trade: Trade
  allowedSlippage: number
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    trade,
    allowedSlippage
  ])
  // const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  // const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  
  return (
    <AutoColumn gap={'4px'} style={{ marginTop: '20px'}}>
      <RowBetween style={{background: '#f2f2fb', borderRadius: '10px', padding: '20px 16px'}} align="center">
        <RowFixed gap={'0px'}>
          <CurrencyLogo currency={trade.inputAmount.currency} size={'36px'} style={{ marginRight: '12px' }} />
          <TruncatedText
            fontSize={16}
            color={"#141416"}
          >
            {trade.inputAmount.currency.symbol}
          </TruncatedText>
        </RowFixed>        
        <Text color={"#141414"} fontWeight={700}>
          {trade.inputAmount.toSignificant(6)}
        </Text>
      </RowBetween>
      <RowFixed>
        {/* <ArrowDown size="16" color={theme.text2} style={{ marginLeft: '4px', minWidth: '16px' }} /> */}
        <img style={{width: '40px', position: 'absolute', left: '50%', marginLeft: '-20px'}} src={ExchangeIcon} alt="Exchange Icon" />
      </RowFixed>
      <RowBetween style={{background: '#f2f2fb', borderRadius: '10px', padding: '20px 16px'}} align="center">
        <RowFixed gap={'0px'}>
          <CurrencyLogo currency={trade.outputAmount.currency} size={'36px'} style={{ marginRight: '12px' }} />
          <TruncatedText
            fontSize={16}
            color={"#141414"}
          >
             {trade.outputAmount.currency.symbol}
          </TruncatedText>
        </RowFixed>        
         <Text color={"#141414"} fontWeight={700}>        
         {trade.outputAmount.toSignificant(6)}
        </Text>
      </RowBetween>
      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap={'0px'}>
          <RowBetween>
            <RowFixed>
              <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} />
              <TYPE.main color={theme.primary1}> Price Updated</TYPE.main>
            </RowFixed>
            <ButtonPrimary
              style={{ padding: '.5rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '12px' }}
              onClick={onAcceptChanges}
            >
              Accept
            </ButtonPrimary>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <TYPE.italic color="#89898a" textAlign="left" style={{ width: '100%' }}>
            {`${t("Output is estimated. You will receive at least")} `}
            <b>
              {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)} {trade.outputAmount.currency.symbol}
            </b>
            {`${t("or the transaction will revert.")}`}
          </TYPE.italic>
        ) : (
          <TYPE.italic color="#89898a" textAlign="left" style={{ width: '100%' }}>
            {`${t("Input is estimated. You will sell at most")} `}
            <b>
              {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)} {trade.inputAmount.currency.symbol}
            </b>
            {`${t("or the transaction will revert.")}`}
          </TYPE.italic>
        )}
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <TYPE.main>
            Output will be sent to{' '}
            <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
          </TYPE.main>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
