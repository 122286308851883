import { ChainId, JSBI, Percent, Token, WBNB } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'

export const ROUTER_ADDRESS = process.env.REACT_APP_ROUTER_ADDRESS!

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// export const DAI = new Token(
//   ChainId.BSCTESTNET,
//   '0x1282911D3600b202698997f61f46379db3B9f01E',
//   18,
//   'DAI',
//   'Dai Stablecoin'
// )

const swapChainId = ChainId.BSCTESTNET
export const USDC = new Token(swapChainId, '0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2', 6, 'USDC', 'USD//C')
// export const USDT = new Token(swapChainId, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'USDT')
export const USDT = new Token(swapChainId, process.env.REACT_APP_USDT_ADDRESS!, 18, 'USDT', 'Tether USD')
export const WBNBToken = new Token(swapChainId, process.env.REACT_APP_WBNB_ADDRESS!, 18, 'WALYX', 'Wrapped ALYX')
// export const TTARC20Token = new Token(swapChainId, process.env.REACT_APP_TTARC20_ADDRESS!, 18, 'TTARC20', 'TTARC20')
export const AUSDToken = new Token(swapChainId, process.env.REACT_APP_AUSD_ADDRESS!, 18, 'AUSD', 'AUSD')
export const SLOTToken = new Token(swapChainId, process.env.REACT_APP_SLOT_ADDRESS!, 18, 'SLOT', 'SLOT')
export const COMP = new Token(swapChainId, '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8', 18, 'COMP', 'Compound')
export const MKR = new Token(swapChainId, '0x5f0Da599BB2ccCfcf6Fdfd7D81743B6020864350', 18, 'MKR', 'Maker')
export const AMPL = new Token(swapChainId, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')

const WBNB_ONLY: ChainTokenList = {
  //  bsc
  [ChainId.MAINNET]: [WBNB[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WBNB[ChainId.ROPSTEN]],
  // [ChainId.RINKEBY]: [WBNB[ChainId.RINKEBY]],
  // [ChainId.GÖRLI]: [WBNB[ChainId.GÖRLI]],
  // [ChainId.KOVAN]: [WBNB[ChainId.KOVAN]],
  [ChainId.BSCTESTNET]: [WBNB[ChainId.BSCTESTNET]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WBNB_ONLY,
  [ChainId.MAINNET]: [...WBNB_ONLY[ChainId.MAINNET], USDT]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [WBNB[ChainId.MAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WBNB_ONLY,
  // [ChainId.BSCTESTNET]: [...WBNB_ONLY[ChainId.BSCTESTNET], WBNBToken, USDT]
  [ChainId.BSCTESTNET]: [WBNBToken, USDT, AUSDToken, SLOTToken]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WBNB_ONLY,
  [ChainId.MAINNET]: [...WBNB_ONLY[ChainId.MAINNET], USDT],
  [ChainId.BSCTESTNET]: [WBNBToken, USDT, AUSDToken, SLOTToken]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  // [ChainId.MAINNET]: [
  //   [
  //     new Token(ChainId.BSCTESTNET, '0x1282911D3600b202698997f61f46379db3B9f01E', 8, 'cDAI', 'Compound Dai'),
  //     new Token(ChainId.BSCTESTNET, '0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2', 8, 'cUSDC', 'Compound USD Coin')
  //   ],
  //   [USDC, USDT],
  //   [DAI, USDT]
  // ],
  [swapChainId]: [[USDT, WBNBToken]]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
