import { CandleChartType, VolumeSeriesDataType } from './index'

// SMA 线
export function calculateSMA(
  data: VolumeSeriesDataType[] | CandleChartType[],
  count: number,
  isVolume = false
): { time: string | number; value: number }[] {
  const avg = function(data: VolumeSeriesDataType[] | CandleChartType[]) {
    let sum = 0
    for (let i = 0; i < data.length; i++) {
      // @ts-ignore
      sum += Boolean(isVolume) ? data[i].value : data[i].close
    }
    return sum / data.length
  }
  const result = []
  for (let i = count - 1, len = data.length; i < len; i++) {
    const val = avg(data.slice(i - count + 1, i))
    result.push({ time: data[i].time, value: val })
  }
  return result
}

export function calculateRSi(data: CandleChartType[], count = 14) {
  const rsiValues: { time: number | string; value: number }[] = []
  const closeArray: { time: number | string; value: number }[] = []

  for (let i = 0; i < data.length; i++) {
    const close = data[i].close // 获取第i个元素的close
    closeArray.push({ time: data[i].time, value: close })
  }

  for (let j = 0; j < closeArray.length; j++) {
    if (j < count) {
      // rsiValues.push({ time: closeArray[j].time, value: null }) // 前n个值为null
      continue
    }

    let sumGain = 0 // 上涨累积值
    let sumLoss = 0 // 下跌累积值

    for (let k = j - count + 1; k <= j; k++) {
      const diff = closeArray[k].value - closeArray[k - 1].value
      if (diff > 0) {
        sumGain += diff
      } else {
        sumLoss -= diff
      }
    }

    const avgGain = sumGain / count // 平均上涨值
    const avgLoss = sumLoss / count // 平均下跌值

    const rs = avgGain / avgLoss // 相对强弱比
    const rsi = 100 - 100 / (1 + rs) // RSI指标

    rsiValues.push({ time: closeArray[j].time, value: rsi })
  }

  return rsiValues
}

function calculateStandardDeviation(data: number[], average: number): number {
  const deviations = data.map(price => Math.pow(price - average, 2))
  const sumOfDeviations = deviations.reduce((sum, deviation) => sum + deviation, 0)
  const variance = sumOfDeviations / data.length
  const standardDeviation = Math.sqrt(variance)

  return standardDeviation
}

export function calculateBoll(data: CandleChartType[], period = 20, deviations = 2) {
  const upperBoll: { time: number | string; value: number }[] = [] // 存储BOLL指标计算结果的数组
  const middleBoll: { time: number | string; value: number }[] = [] // 存储BOLL指标计算结果的数组
  const lowerBoll: { time: number | string; value: number }[] = [] // 存储BOLL指标计算结果的数组

  for (let i = period - 1; i < data.length; i++) {
    const prices = data.slice(i - period + 1, i + 1).map(candle => candle.close)
    const average = prices.reduce((sum, price) => sum + price, 0) / period
    const standardDeviation = calculateStandardDeviation(prices, average)
    const upper = average + deviations * standardDeviation
    const lower = average - deviations * standardDeviation
    upperBoll.push({ time: data[i].time, value: upper })
    middleBoll.push({ time: data[i].time, value: average })
    lowerBoll.push({ time: data[i].time, value: lower })
  }

  return {
    upper: upperBoll,
    middle: middleBoll,
    lower: lowerBoll
  }
}

// export function calculateBoll(kline: CandleChartType[], period = 20, multiplier = 2) {
//   const upperBoll: { time: number | string; value: number }[] = [] // 存储BOLL指标计算结果的数组
//   const middleBoll: { time: number | string; value: number }[] = [] // 存储BOLL指标计算结果的数组
//   const lowerBoll: { time: number | string; value: number }[] = [] // 存储BOLL指标计算结果的数组
//
//   for (let i = 0; i < kline.length; i++) {
//     if (i < period - 1) {
//       // 前几个周期无法计算BOLL指标
//       // boll.push({ upper: null, middle: null, lower: null })
//       continue
//     }
//
//     const sum = kline.slice(i - period + 1, i + 1).reduce((acc, candle) => acc + candle.close, 0)
//     const middle = sum / period // BOLL中轨（中线）
//
//     const squaredSum = kline
//       .slice(i - period + 1, i + 1)
//       .reduce((acc, candle) => acc + Math.pow(candle.close - middle, 2), 0)
//     const standardDeviation = Math.sqrt(squaredSum / period) // BOLL标准差
//
//     const upper = middle + multiplier * standardDeviation // BOLL上轨
//     const lower = middle - multiplier * standardDeviation // BOLL下轨
//
//     upperBoll.push({ time: kline[i].time, value: upper })
//     middleBoll.push({ time: kline[i].time, value: middle })
//     lowerBoll.push({ time: kline[i].time, value: lower })
//   }
//   console.log()
//   return {
//     upper: upperBoll,
//     middle: middleBoll,
//     lower: lowerBoll
//   }
// }

export function calculateKDJ(kline: CandleChartType[], n = 9, m1 = 3, m2 = 3) {
  const kData: { time: number | string; value: number }[] = [] // 存储KDJ指标计算结果的数组
  const dData: { time: number | string; value: number }[] = [] // 存储KDJ指标计算结果的数组
  const jData: { time: number | string; value: number }[] = [] // 存储KDJ指标计算结果的数组
  const highList = []
  const lowList = []
  for (let i = 0; i < kline.length; i++) {
    if (i < n - 1) {
      // 前几个周期无法计算KDJ指标
      continue
    }

    // 添加或删除最高价、最低价到列表中
    highList.push(kline[i].high)
    lowList.push(kline[i].low)
    if (highList.length > n) {
      highList.shift()
      lowList.shift()
    }

    const highN = Math.max(...highList)
    const lowN = Math.min(...lowList)
    const rsv = ((kline[i].close - lowN) / (highN - lowN)) * 100 // RSV
    const k = (m1 * ((kData[i - 1] && kData[i - 1].value) || 50) + rsv) / (m1 + 1) // K值
    const d = (m2 * ((dData[i - 1] && dData[i - 1].value) || 50) + k) / (m2 + 1) // D值
    const j = 3 * k - 2 * d // J值

    kData.push({ time: kline[i].time, value: k })
    dData.push({ time: kline[i].time, value: d })
    jData.push({ time: kline[i].time, value: j })
  }

  return {
    k: kData,
    d: dData,
    j: jData
  }
}

// 计算WR指标
export function calculateWR(kline: CandleChartType[], n = 14) {
  const wr: { time: number | string; value: number }[] = [] // 存储WR指标计算结果的数组

  for (let i = 0; i < kline.length; i++) {
    if (i < n - 1) {
      // 前几个周期无法计算WR指标
      continue
    }

    const highN = Math.max(...kline.slice(i - n + 1, i + 1).map(candle => candle.high))
    const lowN = Math.min(...kline.slice(i - n + 1, i + 1).map(candle => candle.low))

    const wrValue = ((highN - kline[i].close) / (highN - lowN)) * -100 // WR值

    wr.push({ time: kline[i].time, value: wrValue })
  }

  return wr
}
// 计算EMA（指数移动平均线）
function calculateEMA(data: CandleChartType[], n: number) {
  const result: { time: number | string; value: number }[] = []
  const multiplier = 2 / (n + 1)
  result[0] = { time: data[0].time, value: data[0].close }
  for (let i = 1; i < data.length; i++) {
    const value = (data[i].close - result[i - 1]!.value) * multiplier + result[i - 1].value
    result[i] = { time: data[i].time, value }
  }

  return result
}
function calculateEMA2(data: { time: number | string; value: number }[], n: number) {
  const result: { time: number | string; value: number }[] = []
  const multiplier = 2 / (n + 1)

  result[0] = { time: data[0].time, value: data[0].value }
  for (let i = 1; i < data.length; i++) {
    const value = (data[i].value - result[i - 1]!.value) * multiplier + result[i - 1].value
    result[i] = { time: data[i].time, value }
  }

  return result
}

// 计算MACD指标
export function calculateMACD(kline: CandleChartType[], shortPeriod = 12, longPeriod = 26, signalPeriod = 9) {
  // const closePrices = kline.map(candle => candle.close)
  if (!kline.length) {
    return { dif: [], dea: [], macd: [] }
  }
  // 计算短期EMA和长期EMA
  const shortEma = calculateEMA(kline, shortPeriod)
  const longEma = calculateEMA(kline, longPeriod)

  // 计算DIF（快速线）
  const dif = shortEma.map((ema, i) => {
    return {
      time: ema.time,
      value: ema.value - longEma[i].value
    }
  })

  // 计算DEA（信号线）
  const dea = calculateEMA2(dif, signalPeriod)

  // 计算MACD（柱状图）
  const macd = dif.map((val, i) => {
    const value = val.value - dea[i].value
    return {
      time: val.time,
      value,
      color: value < 0 ? '#d96f62' : '#67b9a0'
    }
  })

  return { dif, dea, macd }
}
export function filterKlineDataToHL(klineData: CandleChartType[], from: number, to: number) {
  const filteredData = klineData.filter(candle => {
    const candleTime = candle.time // 假设K线数据中有时间字段为"time"
    return candleTime >= from && candleTime <= to
  })

  if (filteredData.length === 0) {
    return { maxHigh: null, maxHighTime: null, minLow: null, minLowTime: null }
  }

  const maxHighCandle = filteredData.reduce((prevCandle, currCandle) => {
    return currCandle.high > prevCandle.high ? currCandle : prevCandle
  })

  const minLowCandle = filteredData.reduce((prevCandle, currCandle) => {
    return currCandle.low < prevCandle.low ? currCandle : prevCandle
  })

  return {
    maxHigh: maxHighCandle.high,
    maxHighTime: maxHighCandle.time,
    minLow: minLowCandle.low,
    minLowTime: minLowCandle.time
  }
}
