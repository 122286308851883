import React from 'react'
import styled from 'styled-components'
import { ButtonPrimary } from '../../components/Button'
import defiImg from '../../assets/images/defi.png'
// import { AutoColumn } from '../../components/Column'
import CoinIcon from '../../assets/svg/coin.svg'
import MetaIcon from '../../assets/svg/meta.svg'
import WalletIcon from '../../assets/svg/wallet.svg'
import { useResponsive } from 'ahooks'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const DefiContainer = styled.div<{ isXs: boolean }>`
  width: 100%;
  background: #fff;
  padding-top: ${({ isXs }) => (isXs ? '20px' : '180px')};
  padding-bottom: ${({ isXs }) => (isXs ? '40px' : '100px')};
`
const DefiConent = styled.div<{ isXs: boolean }>`
  max-width: ${({ isXs }) => (isXs ? '90%' : '1120px')};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
`
const DefiTitle = styled.div<{ isXs: boolean }>`
  color: #141416;
  font-size: ${({ isXs }) => (isXs ? '24px' : '48px')};
  font-weight: 900;
`
const Defidesc = styled.div<{ isXs: boolean }>`
  color: #141416;
  font-size: ${({ isXs }) => (isXs ? '12px' : '14px')};
  font-weight: 500;
  opacity: 0.5;
  padding-top: 24px;
  max-width: ${({ isXs }) => (isXs ? '235px' : '335px')};
  z-index: 1;
`
const DefiImg = styled.img<{ isXs: boolean }>`
  position: absolute;
  right: 0;
  bottom: ${({ isXs }) => (isXs ? '-100px' : '-160px')};
  right: ${({ isXs }) => (isXs ? '-50px' : '0')};
  width: ${({ isXs }) => (isXs ? '250px' : '435px')};
  height: ${({ isXs }) => (isXs ? '250px' : '490px')};
`
const Partener = styled.div<{ isXs: boolean }>`
  background: #f5f5fa;
  border-radius: 24px;
  padding: 50px;
  max-width: 1120px;
  margin: 0 auto;
  margin-top: ${({ isXs }) => (isXs ? '150px' : '300px')};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 92%;
    margin: 0 auto;
    margin-top: 150px;
    padding: 30px 16px;
  `}
`
const Text = styled.div<{ isXs: boolean }>`
  color: #141416;
  font-size: ${({ isXs }) => (isXs ? '24px' : '36px')};
  font-weight: 900;
`
const PartnerList = styled.div<{ isXs: boolean }>`
  ${({ isXs }) => (isXs ? 'display: flex;' : 'display: grid;')};
  ${({ isXs }) => (isXs ? 'flex-direction: column;' : 'grid-template-columns: 1fr 1fr 1fr;')};
  margin-top: 50px;
  grid-gap: 40px;
`
const PartenerItem = styled.div<{ isXs: boolean }>`
  background: #fff;
  border-radius: 12px;
  padding-bottom: 20px;
`
const LinkWallet = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  margin: 0 auto;
  margin-top: -25px;
  ${({theme}) => theme.mediaWidth.upToExtraSmall`
    width: 40px;
    height: 40px;
    margin-top: -20px;
  `}
`
const LinkText = styled.div<{ isXs: boolean }>`
  font-size: ${({ isXs }) => (isXs ? '14px' : '24px')};
  font-weight: 500;
  color: #141416;
  text-align: center;
  padding-top: 20px;
`

const Defi = ({ addRpc }: { addRpc: any }) => {
  const responsive = useResponsive()
  const isXs = responsive.xs && !responsive.md
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const history = useHistory()

  const list = [
    {
      id: 1,
      title: 'MetaMAsk',
      icon: MetaIcon
    },
    {
      id: 2,
      title: 'MetaMAsk',
      icon: WalletIcon
    },
    {
      id: 3,
      title: 'MetaMAsk',
      icon: CoinIcon
    }
  ]
  return (
    <DefiContainer isXs={isXs}>
      <DefiConent isXs={isXs}>
        <DefiTitle isXs={isXs}>Unleash DeFi’s full potential </DefiTitle>
        <DefiTitle isXs={isXs}>with ALYX</DefiTitle>
        <Defidesc isXs={isXs}>
          Experience ALYX CHAIN’s community sovereignty, the power of global governance and explore the infinite
          possibilities within the ALYX SWAP ecosystem
        </Defidesc>
        <ButtonPrimary
          onClick={() => {
            history.push('/swap')
          }}
          style={{ marginTop: isXs ? 40 : 60, width: isXs ? 220 : 340 }}
        >
          {t('Buy ALYX')}
        </ButtonPrimary>
        <DefiImg isXs={isXs} src={defiImg} />
      </DefiConent>
      <Partener isXs={isXs}>
        <Text style={{ paddingBottom: isXs ? 0 : 40 }} isXs={isXs}>
          Partner
        </Text>
        <PartnerList isXs={isXs}>
          {list.map(item => {
            return (
              <PartenerItem isXs={isXs} key={item.id}>
                <LinkWallet>
                  <img src={item.icon} style={{ borderRadius: '50%', width: '100%' }} />
                </LinkWallet>
                <LinkText isXs={isXs}>{item.title}</LinkText>
                {!account && (
                  <ButtonPrimary
                    onClick={() => {
                      addRpc()
                    }}
                    style={{ margin: '24px auto', width: '80%', height: 52 }}
                  >
                    {t('Connect Wallet')}
                  </ButtonPrimary>
                )}
              </PartenerItem>
            )
          })}
        </PartnerList>
      </Partener>
    </DefiContainer>
  )
}

export default Defi
