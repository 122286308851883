import { useCallback, useEffect, useRef, useState } from 'react'
import {
  CoinExType,
  getCoinExTypeData,
  getKLineData,
  getLatestMarketOne,
  KlineDataApiDataType
} from '../../api/swap/klineData'
import { Currency } from '@uniswap/sdk'
import { ChartData2Type } from './index'
import { BigNumber } from 'bignumber.js'
import { bigNumberTransform } from '../../utils/common'

const swapVersion = process.env.REACT_APP_SWAP_VERSION_V1!

export interface MyCurrency extends Currency {
  address?: string
}

interface UseDefaultChartDataType {
  token: Currency | undefined
  tradeToken: Currency | undefined
}

export interface CurrentLastPriceType {
  price: string
  priceDifference: string
  rate: string
  isTrue: boolean
}

function handleFormatChartDataType(data: KlineDataApiDataType[]): ChartData2Type[] {
  if (!data || !data.length) {
    return []
  }

  const chartData: ChartData2Type[] = []
  for (let i = data.length - 1; i >= 0; i--) {
    const item = data[i]
    const turnover = new BigNumber(item.open)
      .plus(new BigNumber(item.close))
      .plus(new BigNumber(item.high))
      .plus(new BigNumber(item.low))
      .dividedBy(new BigNumber(4))
      .multipliedBy(new BigNumber(item.volume))
      .decimalPlaces(6)
      .toNumber()
    chartData.push({
      close: new BigNumber(item.close).decimalPlaces(6).toNumber(),
      high: new BigNumber(item.high).decimalPlaces(6).toNumber(),
      low: new BigNumber(item.low).decimalPlaces(6).toNumber(),
      open: new BigNumber(item.open).decimalPlaces(6).toNumber(),
      timestamp: item.created_time * 1000,
      volume: new BigNumber(item.volume).decimalPlaces(6).toNumber(),
      turnover
    })
  }
  return chartData
}

export function useDefaultChartData({ token, tradeToken }: UseDefaultChartDataType) {
  //  是否展示K线图 setShowKLineChart
  const [kLineTimeState, setKLineTime] = useState<string>('1Min')
  const [chartData, setChartData] = useState<ChartData2Type[]>([])
  const [updateData, setUpdateData] = useState<ChartData2Type[]>([])
  const timeRef = useRef<NodeJS.Timeout | null>(null)
  const ignore = useRef(false)
  const [coinInfo, setCoinInfo] = useState<CoinExType>({
    price: '0.0000',
    reserves0: '0000',
    reserves1: '0000',
    usd: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    usd_24h_max: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    usd_24h_min: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    usd_24h_vol: 0,
    holder: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    usd_pool_value: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    usd_market_value: 0,
    supply: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    price_24h_change: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    price_24h_max: 0, //24h最高价(币本位
    // eslint-disable-next-line @typescript-eslint/camelcase
    price_24h_min: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    price_24h_vol: 0, //24h交易量(币本位)
    // eslint-disable-next-line @typescript-eslint/camelcase
    price_24h_vol_count: 0, //24h交易次数
    isTrue: false
  })
  // setCurrentLastPrice
  const fetchNewOneKlineData = useCallback(async () => {
    try {
      const MyToken = token as MyCurrency
      const TradeToken = tradeToken as MyCurrency
      const itemData = await getLatestMarketOne({
        token: (MyToken && MyToken.address) as string,
        tradeToken: (TradeToken && TradeToken.address) as string,
        swapVersion: swapVersion,
        type: kLineTimeState
      })
      if (itemData && Object.keys(itemData).length) {
        setUpdateData([...updateData, ...handleFormatChartDataType([itemData])])
      }
    } catch (e) {
      console.log(e)
    }
  }, [updateData, kLineTimeState, token, tradeToken])

  useEffect(() => {
    timeRef.current && clearTimeout(timeRef.current)
    setUpdateData([])
    return () => {
      timeRef.current && clearTimeout(timeRef.current)
    }
  }, [kLineTimeState])
  useEffect(() => {
    return () => {
      timeRef.current && clearTimeout(timeRef.current)
    }
  }, [])
  const fetchCurCoinInfo = useCallback(async () => {
    const MyToken = token as MyCurrency
    const TradeToken = tradeToken as MyCurrency
    const CoinInfoData: CoinExType = await getCoinExTypeData({
      token: (MyToken && MyToken.address) as string,
      tradeToken: (TradeToken && TradeToken.address) as string
    })
    if (!CoinInfoData) {
      return
    }
    const price = bigNumberTransform(CoinInfoData.price, { decimalPlaces: 3 })
    const rate = new BigNumber(CoinInfoData.price_24h_change)
      .multipliedBy(new BigNumber(100))
      .decimalPlaces(2, BigNumber.ROUND_DOWN)
      .toFixed(2)
    //  计算出 当前 数据 最后一条 最新价格  以及 数据的 差额
    const coin24MaxPrice = bigNumberTransform(CoinInfoData!.price_24h_max, { decimalPlaces: 3 })
    const coin24VolPrice = bigNumberTransform(CoinInfoData!.usd_24h_vol, { decimalPlaces: 3 })
    const coin24LowPrice = bigNumberTransform(CoinInfoData!.price_24h_min, { decimalPlaces: 3 })
    const usdMarketValue = bigNumberTransform(CoinInfoData!.usd_market_value, { decimalPlaces: 3 })
    const usdPoolValue = bigNumberTransform(CoinInfoData!.usd_pool_value, { decimalPlaces: 3 })
    const price24hVolCount = bigNumberTransform(CoinInfoData!.price_24h_vol_count, { decimalPlaces: 0 })
    const holder = new BigNumber(CoinInfoData.holder).lt(0)
      ? CoinInfoData.holder
      : bigNumberTransform(CoinInfoData.holder > 0 ? CoinInfoData.holder : 0, { decimalPlaces: 3 })
    setCoinInfo({
      ...CoinInfoData,
      // eslint-disable-next-line @typescript-eslint/camelcase
      usd_24h_max: coin24MaxPrice,
      // eslint-disable-next-line @typescript-eslint/camelcase
      usd_24h_vol: coin24VolPrice,
      // eslint-disable-next-line @typescript-eslint/camelcase
      usd_24h_min: coin24LowPrice,
      // eslint-disable-next-line @typescript-eslint/camelcase
      usd_market_value: usdMarketValue,
      // eslint-disable-next-line @typescript-eslint/camelcase
      price_24h_vol_count: price24hVolCount,
      // eslint-disable-next-line @typescript-eslint/camelcase
      usd_pool_value: usdPoolValue,
      isTrue: new BigNumber(CoinInfoData.price_24h_change).gte(new BigNumber(0)),
      // eslint-disable-next-line @typescript-eslint/camelcase
      price_24h_change: rate,
      price,
      holder
    })
  }, [token, tradeToken])
  useEffect(() => {
    ignore.current = false
    const fetchKlineData = async function() {
      try {
        const MyToken = token as MyCurrency
        const TradeToken = tradeToken as MyCurrency
        const KlineDataApiData: KlineDataApiDataType[] = await getKLineData({
          token: (MyToken && MyToken.address) as string,
          tradeToken: (TradeToken && TradeToken.address) as string,
          swapVersion: swapVersion,
          type: kLineTimeState,
          size: '800'
        })
        fetchCurCoinInfo()
        if (ignore.current) {
          return
        }

        setChartData(handleFormatChartDataType(KlineDataApiData))
        ignore.current = false
        timeRef.current && clearInterval(timeRef.current)
        timeRef.current = setInterval(() => {
          fetchNewOneKlineData()
          fetchCurCoinInfo()
        }, 10000)
      } catch (e) {
        if (e instanceof Error) {
          console.error(e.message)
        }
      }
    }
    fetchKlineData()
    return () => {
      ignore.current = true
    }
  }, [kLineTimeState])
  return {
    chartData,
    kLineTimeState,
    setKLineTime,
    coinInfo,
    updateData
  }
}
