import React from 'react'
import * as echarts from 'echarts/core'
import {
  TitleComponent,
  TitleComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  DataZoomComponent,
  DataZoomComponentOption
} from 'echarts/components'
import { LineChart, LineSeriesOption } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { useResponsive } from 'ahooks'
import { timestampToFormat } from '../../utils'

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
])

type EChartsOption = echarts.ComposeOption<
  | TitleComponentOption
  | ToolboxComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | DataZoomComponentOption
  | LineSeriesOption
>

const EchartLine = ({ echartData, height = '338px' }: { echartData: any; height?: string }) => {
  const xData = echartData?.map((item: any) => timestampToFormat(item.timestamp, 'MM/DD HH:mm'))
  const yData = echartData?.map((item: any) => item.price)
  var option: EChartsOption
  const responsive = useResponsive()
  const isXs = responsive.xs
  let base = +new Date(1988, 9, 3)
  let oneDay = 24 * 3600 * 1000

  let data = [[base, Math.random() * 300]]

  for (let i = 1; i < 200; i++) {
    let now = new Date((base += oneDay))
    data.push([+now, Math.round((Math.random() - 0.5) * 20 + data[i - 1][1])])
  }

  option = {
    tooltip: {
      trigger: 'axis',
      position: function(pt) {
        return [pt[0], '10%']
      }
    },
    grid: {},
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xData
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
      position: 'right'
    },
    series: [
      {
        name: '',
        type: 'line',
        smooth: true,

        symbol: 'none',
        areaStyle: {},
        lineStyle: {
          color: '#0100fc'
        },
        data: yData
      }
    ],
    color: {
      colorStops: [
        {
          offset: 0,
          color: '#9495f7' // 0% 处的颜色
        },
        {
          offset: 1,
          color: '#5659f4' // 100% 处的颜色
        }
      ]
    }
  }

  React.useEffect(() => {
    var chartDom = document.getElementById('line')!
    var myChart = echarts.init(chartDom)
    if (chartDom) {
      option && myChart.setOption(option)
    }
  }, [echartData])

  return <div id="line" style={{ height: height, width: isXs ? '100%' : 590 }}></div>
}
export default EchartLine
