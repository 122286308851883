import React from 'react'
import styled from 'styled-components'
import HomeBg from '../../assets/images/homebg.png'
import HomeBannerBg from '../../assets/images/home-earned.png'
import { ButtonPrimary, ButtonOutlined } from '../../components/Button'
import { Row, AutoColumn } from '../../components/Column'
import EchartLine from './EchartsLine'
import Defi from './Defi'
import Featured from './Featured'
import Footer from './Footer'
import { useResponsive } from 'ahooks'
import { useActiveWeb3React } from '../../hooks'
import { useHistory } from 'react-router-dom'
import { addRpc } from '../../utils'
import { useTranslation } from 'react-i18next'
import getSwapTransactionLogs from '../../utils/eventSwap'

const HomeContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #fff;
`

const HedaerContainer = styled.div`
  background: url(${HomeBg}) no-repeat;
  background-size: cover;
  padding-bottom: 100px;
`

const MainContainer = styled.div<{ isXs: boolean }>`
  max-width: ${({ isXs }) => (isXs ? '90%' : '1120px')}
  margin: 0 auto;
`

const HeaderTitle = styled.div<{ isXs: boolean }>`
  color: #0100fc;
  font-size: ${({ isXs }) => (isXs ? '26px' : '64px')};
  font-weight: 900;
  padding-top: ${({ isXs }) => (isXs ? '50px' : '160px')};
`
const HeaderTitleDesc = styled.div<{ isXs: boolean }>`
  color: #141416;
  font-size: ${({ isXs }) => (isXs ? '26px' : '64px')};
  font-weight: 900;
  padding-bottom: ${({ isXs }) => (isXs ? '20px' : '24px')};
`
const Text = styled.div<{ isXs: boolean }>`
  color: #141416;
  font-size: ${({ isXs }) => (isXs ? '12px' : '24px')};
  font-weight: ${({ isXs }) => (isXs ? '400' : '500')};
`
const BannerContainer = styled.div<{ isXs: boolean }>`
  width: 100%;
  height: ${({ isXs }) => (isXs ? '160px' : '304px')};
  background: #fff;
  border-radius: 12px;
  margin-top: ${({ isXs }) => (isXs ? '26px' : '92px')};
  background: url(${HomeBannerBg}) no-repeat no-repeat;
  background-size: ${({ isXs }) => (isXs ? 'cover' : 'cover')};
`
const BannerConent = styled.div<{ isXs: boolean }>`
  padding: ${({ isXs }) => (isXs ? '46px 12px' : '100px 60px')};
  display: flex;
  flex-direction: column;
`
const BannerTitle = styled.div<{ isXs: boolean }>`
  color: #fff;
  font-size: ${({ isXs }) => (isXs ? '12px' : '24px')};
`
const BannerDesc = styled.div<{ isXs: boolean }>`
  color: #fff;
  font-size: ${({ isXs }) => (isXs ? '20px' : '48px')};
  font-weight: 700;
  margin-top: 8px;
  text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.3);
`
const EchartContianer = styled.div<{ isXs: boolean }>`
  margin-top: ${({ isXs }) => (isXs ? '40px' : '180px')};
`
const LineContainer = styled.div`
  background: #fff;
  width: 590px;
  border-radius: 12px;
  padding-bottom: 20px;
`
const FluidityContainer = styled.div<{ active: boolean; isXs: boolean }>`
  font-size: ${({ isXs }) => (isXs ? '16px' : '32px')};
  font-weight: ${({ active }) => (active ? 700 : 400)};
  margin-right: 80px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#141416' : '#7b7e89')};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: ${({ isXs }) => (isXs ? '-10px' : '-20px')};
    left: 50%;
    width: 80%;
    margin-left: -40%;
    height: 4px;
    background: ${({ active }) => (active ? '#0100fc' : 'transparent')};
  }
`
const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
`
const InformationTitle = styled.div<{ isXs: boolean }>`
  font-size: ${({ isXs }) => (isXs ? '24px' : '48px')};
  font-weight: 900;
  color: #141416;
  ${({ isXs }) => isXs && 'text-align: center'}
`
const InformationItem = styled.div<{ isXs: boolean }>`
  font-size: ${({ isXs }) => (isXs ? '12px' : '18px')};
  font-weight: 500;
  color: ${({ isXs }) => (isXs ? '#808080' : '#808080')};
  margin-top: ${({ isXs }) => (isXs ? '10px' : '20px')};
  display: flex;
  align-items: center;
  ${({ isXs }) => isXs && 'justify-content: center'}
`

const LineTitle = styled.div`
  font-size: 32px;
  color: #141416;
  font-weight: 900;
  padding-top: 30px;
  padding-left: 30px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding-top: 20px;
      padding-left: 12px;
      font-size: 20px;
      font-weight: 900;
      color: #141416;
  `}
`
const LineTitleDesc = styled.div`
  padding-left: 30px;
  color: #89898a;
  font-size: 12px;
  padding-top: 10px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding-left: 12px;
      padding-top: 8px;
  `}
`
const Home = () => {
  const [type, setType] = React.useState('fluidity')
  const responsive = useResponsive()
  const isXs = responsive.xs && !responsive.md
  const { account } = useActiveWeb3React()
  const history = useHistory()
  const { t } = useTranslation()
  const [echartData, setEchartData] = React.useState<any>([])

  React.useEffect(() => {
    getSwapTransactionLogs().then(res => {
      setEchartData(res)
    })
  }, [])

  return (
    <HomeContainer>
      <HedaerContainer>
        <MainContainer isXs={isXs}>
          <HeaderTitle isXs={isXs}>ALYX CHAIN</HeaderTitle>
          <HeaderTitleDesc isXs={isXs}>Decentralized Exchange</HeaderTitleDesc>
          <Text
            isXs={isXs}
            color={'#141414'}
            style={{
              width: isXs ? '270px' : 'auto'
            }}
          >
            That is loved by everyone in the industry and integrates trading, profit, storage and
            <Text isXs={isXs}>other functions</Text>
          </Text>
          <Row style={{ marginTop: isXs ? 20 : 52 }}>
            {!account && (
              <ButtonOutlined
                onClick={() => {
                  addRpc()
                }}
                style={{ width: isXs ? 160 : 226, height: isXs ? 48 : 'auto', marginRight: 24 }}
              >
                {t('Connect Wallet')}
              </ButtonOutlined>
            )}
            <ButtonPrimary
              onClick={() => {
                history.push('/swap')
              }}
              style={{ width: isXs ? 160 : 226, height: isXs ? 48 : 'auto' }}
            >
              {t('Trade now')}
            </ButtonPrimary>
          </Row>
          <Row style={{ marginTop: 52, flexWrap: isXs ? 'wrap' : 'nowrap' }}>
            <AutoColumn
              justify="center"
              style={{ marginBottom: isXs ? 20 : 0, marginRight: isXs ? 0 : 100, width: isXs ? '48%' : 'auto' }}
            >
              <Text isXs={isXs} style={{ fontSize: isXs ? 20 : 36, fontWeight: 700 }}>
                $ 0.504
              </Text>
              <Text
                isXs={isXs}
                style={{ fontSize: isXs ? 12 : 16, fontWeight: 500, color: '#141416', opacity: 0.5, paddingTop: 10 }}
              >
                ALYX price
              </Text>
            </AutoColumn>
            <AutoColumn
              justify="center"
              style={{ marginBottom: isXs ? 20 : 0, marginRight: isXs ? 0 : 100, width: isXs ? '48%' : 'auto' }}
            >
              <Text isXs={isXs} style={{ fontSize: isXs ? 20 : 36, fontWeight: 700 }}>
                31.3 Bn
              </Text>
              <Text
                isXs={isXs}
                style={{ fontSize: isXs ? 12 : 16, fontWeight: 500, color: '#141416', opacity: 0.5, paddingTop: 10 }}
              >
                ALYX total amount
              </Text>
            </AutoColumn>
            <AutoColumn justify="center" style={{ marginRight: isXs ? 0 : 100, width: isXs ? '48%' : 'auto' }}>
              <Text isXs={isXs} style={{ fontSize: isXs ? 20 : 36, fontWeight: 700 }}>
                2.0137 Bn
              </Text>
              <Text
                isXs={isXs}
                style={{ fontSize: isXs ? 12 : 16, fontWeight: 500, color: '#141416', opacity: 0.5, paddingTop: 10 }}
              >
                ALYX circulation
              </Text>
            </AutoColumn>
            <AutoColumn justify="center" style={{ width: isXs ? '48%' : 'auto' }}>
              <Text isXs={isXs} style={{ fontSize: isXs ? 20 : 36, fontWeight: 700 }}>
                5,370,000
              </Text>
              <Text
                isXs={isXs}
                style={{ fontSize: isXs ? 12 : 16, fontWeight: 500, color: '#141416', opacity: 0.5, paddingTop: 10 }}
              >
                ALYX burnt amount
              </Text>
            </AutoColumn>
          </Row>
          <BannerContainer isXs={isXs}>
            <BannerConent isXs={isXs}>
              <BannerTitle isXs={isXs}>Trade to earn rewards</BannerTitle>
              <BannerDesc isXs={isXs}>10% trading rebate to be earned!</BannerDesc>
              <Row style={{ marginTop: 20 }}>
                <ButtonOutlined
                  style={{
                    height: isXs ? 32 : 'auto',
                    width: isXs ? 120 : 186,
                    marginRight: 20,
                    borderColor: '#fff',
                    color: '#fff',
                    fontSize: isXs ? 12 : 16
                  }}
                >
                  Learn more
                </ButtonOutlined>
                <ButtonPrimary
                  style={{
                    fontSize: isXs ? 12 : 16,
                    height: isXs ? 32 : 'auto',
                    width: isXs ? 110 : 185,
                    background: '#fff',
                    color: '#0100FC'
                  }}
                  onClick={() => {
                    history.push('/swap')
                  }}
                >
                  {t('Trade now')}
                </ButtonPrimary>
              </Row>
            </BannerConent>
          </BannerContainer>
          {isXs && (
            <InformationContainer>
              <InformationTitle isXs={isXs}>ALYX information</InformationTitle>
              <InformationItem isXs={isXs}>
                ALYX market capitalization
                <Text isXs={isXs} style={{ color: '#0100FC', fontSize: isXs ? 12 : 18, paddingLeft: isXs ? 0 : 10 }}>
                  $ 94,098,724.28
                </Text>
              </InformationItem>
              <ButtonPrimary
                onClick={() => {
                  addRpc()
                }}
                style={{ margin: isXs ? '40px auto 0 auto' : '60px auto 0 auto', width: isXs ? '60%' : 'auto' }}
              >
                {t('Link wallet')}
              </ButtonPrimary>
            </InformationContainer>
          )}
          <EchartContianer isXs={isXs}>
            <Row style={{ marginBottom: 40 }}>
              <FluidityContainer isXs={isXs} active={type === 'fluidity'} onClick={() => setType('fluidity')}>
                Fluidity
              </FluidityContainer>
              <FluidityContainer isXs={isXs} active={type === 'trading'} onClick={() => setType('trading')}>
                24h Trading volume
              </FluidityContainer>
            </Row>
            <Row
              style={{
                justifyContent: 'space-between',
                alignItems: 'flex-start'
              }}
            >
              <LineContainer>
                <LineTitle>$ 219.837,3847</LineTitle>
                <LineTitleDesc>October 20 , 2023</LineTitleDesc>
                <EchartLine echartData={echartData} />
              </LineContainer>
              {!isXs && (
                <InformationContainer>
                  <InformationTitle isXs={isXs}>ALYX information</InformationTitle>
                  <InformationItem isXs={isXs}>
                    ALYX market capitalization
                    <Text isXs={isXs} style={{ color: '#0100FC', fontSize: 18, paddingLeft: 10 }}>
                      $ 94,098,724.28
                    </Text>
                  </InformationItem>
                  {!account && (
                    <ButtonPrimary
                      onClick={() => {
                        addRpc()
                      }}
                      style={{ marginTop: 60 }}
                    >
                      {t('Connect Wallet')}
                    </ButtonPrimary>
                  )}
                </InformationContainer>
              )}
            </Row>
          </EchartContianer>
        </MainContainer>
      </HedaerContainer>
      <Defi addRpc={addRpc} />
      <Featured />
      <Footer />
    </HomeContainer>
  )
}

export default Home
