// import { ChainId } from '@uniswap/sdk'
import React, { useState } from 'react'
// import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
// import { ConnectorUpdate } from '@web3-react/types'
import styled from 'styled-components'
// import { useWeb3React } from '@web3-react/core'
import { useActiveWeb3React } from '../../hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { AlertCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import Language from '../Language'
import Settings from '../Settings'
import Mnav from '../Mnav'
import Nav from './Nav'
import Row, { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
import logo from '../../assets/images/logo.png'
import { useResponsive } from 'ahooks'
import { ethers } from 'ethers'
import { addRpc, getALYXPrice } from '../../utils'
import ALYXIcon from '../../assets/images/alyx.png'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  padding-bottom: 10px;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 0 0 0;
    width: calc(100%);
    position: relative;
  `};
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(20px);
}
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0rem;
`};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // display: none;
  `};
`

const AccountElement = styled.div<{ active: boolean; userEthBalance?: any; isXs: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f2f2fd;
  border-radius: 8px;
  padding: ${({ userEthBalance, isXs }) => (userEthBalance && !isXs ? '0 0 0 12px' : '0')};
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`
const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
  @media (max-width: 721px) {
    /* display: none; */
  }
`

const NetworkCard = styled.div`
  margin-right: 10px;
  border-radius: 8px;
  padding: 2px 6px;
  border: 1px solid #0100fc;
  color: #0100fc;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    align-items: center;
  `};
`

const BalanceText = styled(Text)`
  color: #0100fc;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const SymbolText = styled.span`
  color: #0100fc;
`

const AlyxCoin = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 500;
`

// const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
//   [ChainId.MAINNET]: null,
//   // [ChainId.RINKEBY]: 'Eth',
//   [ChainId.ROPSTEN]: 'Ropsten',
//   // [ChainId.GÖRLI]: 'Görli',
//   // [ChainId.KOVAN]: 'Kovan',
//   [ChainId.BSCTESTNET]: 'ALYX Test Chain'
// }

export default function Header() {
  // const { error } = useWeb3React()
  const { account } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const responsive = useResponsive()
  const isXs = responsive.xs && !responsive.md
  const isMetamask = window?.ethereum?.isMetaMask
  // @ts-ignore
  const provider = isMetamask ? new ethers.providers.Web3Provider(window.ethereum) : null
  const { t } = useTranslation()
  const [currentChainId, setCurrentChainId] = useState()
  const [ALYXPrice, setALYXPrice] = useState<any>()

  React.useEffect(() => {
    if (provider) {
      provider.getNetwork().then(res => {
        // console.log(res, '当前连接的节点')
        // @ts-ignore
        setCurrentChainId(res?.chainId)
      })
      getALYXPrice().then(res => setALYXPrice(res))
    }
  }, [provider])

  return (
    <HeaderFrame>
      <RowBetween className={'mobileStyleClass'} padding={'1rem 1rem 0 1rem'}>
        <HeaderElement>
          <Title href=".">
            <TitleText>
              <img style={{ width: isXs ? '98px' : '158px', height: isXs ? '22px' : '40px' }} src={logo} alt="logo" />
            </TitleText>
          </Title>
          {!isXs && <Nav />}
        </HeaderElement>
        <HeaderControls>
          {!isXs && provider && (
            <AlyxCoin>
              <img style={{ width: '26px', height: '26px' }} src={ALYXIcon} alt="logo" />
              <span style={{ marginLeft: '10px' }}>
                ALYX {t('Price')} ≈ ${ALYXPrice}
              </span>
            </AlyxCoin>
          )}
          <HeaderElement>
            <TestnetWrapper onClick={addRpc}>
              {isMetamask && currentChainId !== 1314 && (
                <NetworkCard style={{ borderColor: '#ff6871' }}>
                  <AlertCircle style={{ width: 20, color: '#ff6871' }} />
                  <div style={{ paddingLeft: 8, color: '#ff6871' }}>切换 ALYX 网络</div>
                </NetworkCard>
              )}
            </TestnetWrapper>
            <AccountElement
              active={!!account}
              userEthBalance={userEthBalance}
              isXs={isXs}
              style={{ pointerEvents: 'auto' }}
            >
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(6)} <SymbolText>ALYX</SymbolText>
                </BalanceText>
              ) : (
                <></>
              )}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            <Language />
            <Settings />
            {isXs && <Mnav />}
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
