import { ethers } from 'ethers'

// 要查询的代币合约地址
// USDT 0x311C229767c3dcee6fCc84946587A15d96e51Cd5
// wALYX 0x6Cc2cD3CaA62b9b4f4BCB462404e7D8968377bd4
const provider = new ethers.providers.JsonRpcProvider('https://rpc.alyxchain.com')

const tokenAddress = '0x6Cc2cD3CaA62b9b4f4BCB462404e7D8968377bd4'

// 根据 factory地址 0xc1FA53446a3AFcf1d48ee587d28CdE0515B22Ab8 查询lp合约地址
const factoryAddress = '0xc1FA53446a3AFcf1d48ee587d28CdE0515B22Ab8'
const factoryABI = ['function getPair(address tokenA, address tokenB) external view returns (address pair)']
const alyxFactory = new ethers.Contract(factoryAddress, factoryABI, provider)

// 创建一个 Contract 对象来调用代币合约的方法
const tokenContract = new ethers.Contract(
  tokenAddress,
  ['event Transfer(address indexed from, address indexed to, uint256 value)'],
  provider
)

// 创建一个 Filter 对象以过滤指定代币的转账事件
const filter = tokenContract.filters.Transfer(null, null, null) // 这将匹配所有的 Transfer 事件
// 可以根据需要进一步细化过滤条件，例如只关注特定的发送者或接收者：tokenContract.filters.Transfer(senderAddress, null, null)

// 监听符合过滤条件的事件
tokenContract.on(filter, (from, to, value, event) => {
  // console.log('Transfer event:', event)
})

// 如果是多个交易对 对应多个 LP
// 1.调用router的factory()方法，拿到factory合约地址
// 2.调用factory的getPair(address,address)方法，这个返回的结果就是LP合约地址

// 获取过去的 swap 交易历史日志
export default async function getSwapTransactionLogs(currency = 'ALYX', inToken?: any, outToken?: any) {
  const lpAddress = await alyxFactory.getPair(
    inToken?.address || '0x6Cc2cD3CaA62b9b4f4BCB462404e7D8968377bd4',
    outToken?.address || '0x6Cc2cD3CaA62b9b4f4BCB462404e7D8968377bd4'
  )
  // console.log('LP 地址:', inToken?.address, outToken?.address, lpAddress)
  const routerContract = new ethers.Contract(
    lpAddress,
    [
      'event Swap(address indexed sender, uint256 amount0In, uint256 amount1In, uint256 amount0Out, uint256 amount1Out, address indexed to)'
    ],
    provider
  )

  // 创建一个 Filter 对象以过滤 swap 事件
  const filter = routerContract.filters.Swap(null, null, null, null, null, null) // 这将匹配所有的 Swap 事件
  // 获取当前区块高度
  // const latestBlockNumber = await provider.getBlockNumber()
  // 获取过去的交易日志
  const logs = await provider.getLogs({
    fromBlock: 0,
    toBlock: 'latest',
    address: lpAddress,
    topics: filter.topics
  })
  // console.log('Swap logs:', logs)

  // 转换日志为交易时间和价格
  const transactions = logs.map(async log => {
    const parsedLog = routerContract.interface.parseLog(log)
    let timestamp = 0
    await provider.getBlock(log.blockNumber).then(block => {
      timestamp = block.timestamp
    })
    // const price = parsedLog.args.amount1Out / parsedLog.args.amount0In // 假设获取的是 amount0In 和 amount1Out
    const price =
      currency !== 'ALYX' && currency !== 'WALYX'
        ? parsedLog.args.amount1Out / parsedLog.args.amount0In
        : parsedLog.args.amount0Out / parsedLog.args.amount1In // 假设获取的是 amount0In 和 amount1Out

    return {
      transactionHash: log.transactionHash,
      timestamp,
      price
    }
  })

  let logArr: any = []
  // 异步获取交易日志
  let transactionLogs = await Promise.all(transactions)
  for (let i = 0; i < transactionLogs.length; i++) {
    if (transactionLogs[i].price) {
      logArr.push(transactionLogs[i])
      logArr.sort((a: any, b: any) => a.timestamp - b.timestamp)
    }
  }
  // console.log('Swap log:', logArr)
  return logArr
}
