import React, { forwardRef, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react'
import { Context } from './KlineChartParent'
import { IChartApi, ISeriesApi } from 'lightweight-charts'
const KChartCandleSeries = forwardRef((props: any, ref) => {
  const parent = useContext(Context) as { _api: IChartApi; api: () => IChartApi; free: () => void }
  const context = useRef<{ _api?: ISeriesApi<'Candlestick'>; api: () => ISeriesApi<'Candlestick'>; free: () => void }>({
    api() {
      if (!this._api) {
        const { children, data, priceFormat, autoscaleInfoProvider, ...rest } = props
        this._api = parent.api().addCandlestickSeries({
          priceFormat:
            priceFormat && typeof priceFormat === 'object'
              ? priceFormat
              : {
                  type: 'volume',
                  precision: 3,
                  minMove: 0.001
                },
          autoscaleInfoProvider:
            autoscaleInfoProvider && typeof autoscaleInfoProvider === 'function'
              ? autoscaleInfoProvider
              : (original: any) => {
                  const res = original()
                  if (res !== null) {
                    res.priceRange.minValue = 0
                    res.priceRange.maxValue += 0.001
                  }
                  return res
                },
          priceScaleId: 'right',
          ...rest
        })
      }
      return this._api
    },
    free() {
      if (this._api) {
        parent && parent.free()
      }
    }
  })
  useEffect(() => {
    const currentRef = context.current
    currentRef.api().setData(props.data)
  }, [props.data])

  useEffect(() => {
    if (!props.scaleMargins) {
      return
    }
    const currentRef = context.current
    currentRef
      .api()
      .priceScale()
      .applyOptions({
        scaleMargins: props.scaleMargins
      })
  }, [props.scaleMargins])
  useLayoutEffect(() => {
    const currentRef = context.current
    const { children, data, ...rest } = props
    currentRef.api().applyOptions({
      ...rest
    })
  })

  useImperativeHandle(ref, () => context.current.api(), [])

  return <Context.Provider value={context.current}>{props.children}</Context.Provider>
})
export default KChartCandleSeries
