import { BigNumber } from 'bignumber.js'

export function myDebounce(func: Function, wait: number) {
  if (typeof func !== 'function') {
    throw new TypeError('need a function')
  }
  wait = +wait || 0

  let timeId: NodeJS.Timeout | null = null

  return () => {
    // @ts-ignore
    const self = this
    // @ts-ignore
    const args = arguments

    timeId && clearTimeout(timeId) // 清除定时器，重新设定一个新的定时器
    timeId = setTimeout(() => {
      func.apply(self, args) // arguments 是传给函数的参数，这里是 event  对象
    }, wait)
  }
}

export function formTimeStamp2Date(timer: number | string, type = 'YYYY/MM/DD HH:mm:SS') {
  // 时间戳：1637244864707
  const formatType = type.indexOf('-') > -1 ? '-' : '/'
  /* 时间戳转换为时间 */
  const timestamp: number | null = timer ? Number(timer) * 1000 : null
  const date = new Date(timestamp as number) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + formatType
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + formatType
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}

export function formTimeStamp2DateTime(timer: number | string, type = 'MM/DD HH:mm') {
  // 时间戳：1637244864707
  const formatType = type.indexOf('-') > -1 ? '-' : '/'
  /* 时间戳转换为时间 */
  const timestamp: number | null = timer ? Number(timer) * 1000 : null
  const date = new Date(timestamp as number) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + formatType
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  return M + D + h + m
}

export function formTimeStamp2DateTime2(timer: number | string, type = 'MM/DD HH:mm') {
  // 时间戳：1637244864707
  const formatType = type.indexOf('-') > -1 ? '-' : '/'
  /* 时间戳转换为时间 */
  const timestamp: number | null = timer ? Number(timer) * 1000 : null
  const date = new Date(timestamp as number) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + formatType
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + formatType
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  return Y + M + D + h + m
}

export function formTimeStamp2DateTime3(timer: number | string, type = 'YY/MM/DD HH:mm') {
  // 时间戳：1637244864707
  const formatType = type.indexOf('-') > -1 ? '-' : '/'
  /* 时间戳转换为时间 */
  const timestamp: number | null = timer ? Number(timer) * 1000 : null
  const date = new Date(timestamp as number) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y =
    date
      .getFullYear()
      .toString()
      .substring(2) + formatType
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + formatType
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  return Y + M + D + h + m
}
export function formTimeStamp2DateTime4(timer: number | string) {
  /* 时间戳转换为时间 */
  const timestamp: number | null = timer ? Number(timer) * 1000 : null
  const date = new Date(timestamp as number) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  return h + m
}
//
// // 数字 取整  默认 6位数 向下取整
export function $fitDigitsNum(num = '0', digit = 3, Roud = BigNumber.ROUND_DOWN) {
  return new BigNumber(num).decimalPlaces(digit, Roud).toString()
}

// 大数 乘法
export function $fitMultiplicationNum(num = '0', num2 = '0', digit = 6) {
  return new BigNumber(num)
    .multipliedBy(new BigNumber(num2))
    .decimalPlaces(digit)
    .toString()
}

// 大数 除法
// eslint-disable-next-line
export function $fitDivisionNum(num = '0', num2 = '1', digit =  6) {
  return new BigNumber(num)
    .dividedBy(new BigNumber(num2))
    .decimalPlaces(digit)
    .toString()
}

// 大数 减法
// eslint-disable-next-line
export function $fitSubtractionNum(num = '0', num2 = '1', digit =  6) {
  return new BigNumber(num)
    .minus(new BigNumber(num2))
    .decimalPlaces(digit)
    .toString()
}

// 大数 累加
// eslint-disable-next-line
export function $additionNum(num = '0', num2 = '1', digit =  6) {
  return new BigNumber(num)
    .plus(new BigNumber(num2))
    .decimalPlaces(digit)
    .toString()
}

// 比较大小
// eslint-disable-next-line
export function $compareTo(num = '0', num2 = '1', digit =  6) {
  return new BigNumber(num).comparedTo(new BigNumber(num2))
}

export const formatNumber = (
  value: BigNumber.Value,
  decimalPlaces = 2,
  roundingMode: BigNumber.RoundingMode,
  config?: BigNumber.Format
) => {
  return new BigNumber(value).toFormat(decimalPlaces, roundingMode, config)
}

export const formatMoney = (
  value: BigNumber.Value | number | string,
  config?: {
    unit?: string
    space?: boolean
    decimalPlaces?: number
  }
) => {
  const { unit = '', space = false, decimalPlaces = 4 } = config || {}
  return formatNumber(value, decimalPlaces, 1, {
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: '',
    prefix: `${unit}${space ? ' ' : ''}`
  })
}
/**
 * 数字转换，将大额数字转换为万、亿、万亿等
 * @param value 数字值
 */
export function bigNumberTransform(
  value: string | number,
  config?: {
    unit?: string
    space?: boolean
    decimalPlaces?: number
  }
) {
  if (!value || value == 0) {
    return '0.0000'
  }
  const bigNum = new BigNumber(value)

  if (bigNum.isNaN()) {
    return '0.0000'
  }
  const { unit = '', space = false, decimalPlaces = 2 } = config || {}
  if (bigNum.gte(1000000)) {
    const simplifiedNum = bigNum
      .div(1000000)
      .decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN)
      .toFormat(decimalPlaces, {
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
        secondaryGroupSize: 0,
        fractionGroupSeparator: ' ',
        fractionGroupSize: 0,
        suffix: '',
        prefix: `${unit}${space ? ' ' : ''}`
      })
    return `${simplifiedNum}M`
  }

  if (bigNum.gte(1000)) {
    const simplifiedNum = bigNum
      .div(1000)
      .decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN)
      .toFormat(decimalPlaces, {
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
        secondaryGroupSize: 0,
        fractionGroupSeparator: ' ',
        fractionGroupSize: 0,
        suffix: '',
        prefix: `${unit}${space ? ' ' : ''}`
      })
    return `${simplifiedNum}K`
  }
  // 0.000001
  if (bigNum.isLessThan(new BigNumber(0.001))) {
    const str = bigNum.toString()
    if (str.indexOf('e') > -1) {
      const numStr = str.split('e')[0]
      const allDecimalPlaces = str.split('e')[1]
      const realValue = new BigNumber(numStr)
        .decimalPlaces(decimalPlaces - 1, BigNumber.ROUND_DOWN)
        .toString()
        .replace('.', '')
      return `0.{${new BigNumber(allDecimalPlaces)
        .plus(new BigNumber(1))
        .abs()
        .toString()}}${realValue}`
    }
    const match = str.match(/[1-9]/)
    if (match && match.index) {
      const zeroIndex = match.index - 2
      const substr = str.slice(match.index, str.length > 5 ? str.length - 1 : str.length)
      return `0.{${zeroIndex}}${substr}`
    }
  }

  return bigNum.decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN).toFormat(decimalPlaces)
}
