import React, { useRef } from 'react'
import styled from 'styled-components'
import useToggle from '../../hooks/useToggle'
import { useHistory } from 'react-router-dom'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`
const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  /* background-color: ${({ theme }) => theme.bg3}; */

  color: #212429;
  font-size: 0.7rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }

  svg {
    margin-top: 2px;
  }
`
const MenuFlyout = styled.span`
  min-width: 15.125rem;
  background-color: rgba(255, 255, 255);
  backdrop-filter: blur(15px);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0rem;
  z-index: 100;
`
const MenuItem = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text1};
  :hover {
    color: #0100fc;
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

const Mnav = () => {
  const node = useRef<HTMLDivElement>()
  const [open, toggle] = useToggle(false)
  const history = useHistory()
  useOnClickOutside(node, open ? toggle : undefined)

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="2" fill="#141416" />
          <rect y="5" width="16" height="2" fill="#141416" />
          <rect y="10" width="16" height="2" fill="#141416" />
        </svg>
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          <MenuItem
            onClick={() => {
              history.push('/')
              toggle()
            }}
          >
            Home
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push('/swap')
              toggle()
            }}
          >
            Swap
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push('/pool')
              toggle()
            }}
          >
            Liquidity
          </MenuItem>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}

export default Mnav
