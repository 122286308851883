import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { ROUTER_ADDRESS } from '../constants'
import { ChainId, JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from '@uniswap/sdk'
import { TokenAddressMap } from '../state/lists/hooks'
import { ethers } from 'ethers'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  1: 'https://etherscan.io',
  56: 'https://bscscan.com',
  1314: 'https://www.alyxscan.com'
}

export function getEtherscanLink(chainId: ChainId, data: string, type: 'transaction' | 'token' | 'address'): string {
  const prefix = `${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[56]}`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000))
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export async function addRpc() {
  if (!window.ethereum) return
  try {
    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x522' }]
    })
  } catch (switchError) {
    // 4902 error code indicates the chain is missing on the wallet
    // @ts-ignore
    if (switchError.code === 4902) {
      try {
        // @ts-ignore
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x522',
              rpcUrls: ['https://rpc.alyxchain.com'],
              chainName: 'ALYX Mainnet',
              nativeCurrency: { name: 'ALYX', decimals: 18, symbol: 'ALYX' },
              blockExplorerUrls: ['https://www.alyxscan.com']
              // iconUrls: ['https://alyxchain.com/favicon.ico']
            }
          ]
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}

// 获取 2 个token 之间的汇率
// if (!window.ethereum) return
export const getALYXPrice = async () => {
  // @ts-ignore
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const routerAddress = '0x986dc6e4B86f72b43591B3d0D95b27418D0579Ec'
  const WALYXAddress = '0x6Cc2cD3CaA62b9b4f4BCB462404e7D8968377bd4'
  const USDTAddress = '0x311C229767c3dcee6fCc84946587A15d96e51Cd5'
  // 构建 router
  const router: any = new ethers.Contract(
    routerAddress,
    [
      'function getAmountsOut(uint amountIn, address[] memory path) public view returns (uint[] memory amounts)',
      'function getAmountsIn(uint amountOut, address[] memory path) public view returns (uint[] memory amounts)'
    ],
    // @ts-ignore
    provider
  )
  const amounts = await router.getAmountsOut(ethers.utils.parseEther('1'), [WALYXAddress, USDTAddress])
  return (amounts?.[1].toString() / 1e18).toFixed(4)
}

// 时间戳转为 YYYY-MM-DD HH:mm:ss 格式
export const timestampToFormat = (timestamp: number, format: string) => {
  const date = new Date(timestamp * 1000)
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/'
  const D = date.getDate() + ' '
  const h = date.getHours() + ':'
  const m = date.getMinutes() + ':'
  const s = date.getSeconds()
  if (format === 'YYYY/MM/DD') return Y + M + D
  if (format === 'HH:mm:ss') return h + m + s
  if (format === 'MM/DD HH:mm:ss') return M + D + h + m + s
  if (format === 'MM/DD HH:mm') return M + D + h + m.replace(':', '')
  return Y + M + D + h + m + s
}
