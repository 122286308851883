import { Currency } from '@uniswap/sdk'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  CoinExType,
  CoinInfoDetailType,
  CoinObjectInfoDetail,
  getCoinExTypeData,
  getCoinInfoDetail,
  getLatestTradeAddressList,
  getTradeRecordList,
  LatestTradeAddressListItem,
  ListResultType,
  TradeRecordListItemType
} from '../../../api/swap/klineData'
import { PageTypeParams } from '../../../api/common'
import { MyCurrency } from '../../KLineChart/hook2'
import { useCurrentLangManager } from '../../../state/user/hooks'

const swapVersion = process.env.REACT_APP_SWAP_VERSION_V1!
interface UseDefaultChartDataType {
  token: Currency | undefined
  tradeToken: Currency | undefined
}

// 获取 介绍显示
export function useIntroduceViewHook({
  token,
  tradeToken
}: UseDefaultChartDataType): { coinInfo: CoinObjectInfoDetail | {} } {
  const [userLang] = useCurrentLangManager()
  const ignore = useRef(false)
  const [coinInfo, setConInfo] = useState<CoinObjectInfoDetail | {}>({})
  const init = useCallback(
    async function() {
      try {
        const MyToken = token as MyCurrency
        const TradeToken = tradeToken as MyCurrency
        const [coinBaseInfo, coinInfoDetail]: [CoinExType, CoinInfoDetailType] = await Promise.all([
          getCoinExTypeData({
            token: (MyToken && MyToken!.address) as string,
            tradeToken: (TradeToken && TradeToken!.address) as string
          }),
          getCoinInfoDetail({
            token: (MyToken && MyToken!.address) as string,
            lang: userLang === 'zh' ? 'zh-cn' : 'en'
          })
        ])
        if (ignore.current) {
          return
        }
        setConInfo({
          ...coinBaseInfo,
          ...coinInfoDetail
        })
        ignore.current = false
      } catch (e) {
        console.log(e)
      }
    },
    [token, tradeToken, userLang]
  )
  useEffect(() => {
    ignore.current = false
    init()
    return () => {
      ignore.current = true
    }
  }, [token, tradeToken, userLang])
  return {
    coinInfo
  }
}

// 获取 列表数据
export function useQuotesTradeListHook({ token, tradeToken }: UseDefaultChartDataType) {
  const [params, setParams] = useState<PageTypeParams>({
    page: 1,
    size: 30,
    lastPage: 1
  })
  const [lists, setLists] = useState<TradeRecordListItemType[]>([])
  const ignore = useRef(false)
  const [loading, setLoading] = useState(false)
  const fetchLists = useCallback(
    async function() {
      try {
        setLoading(true)
        const MyToken = token as MyCurrency
        const TradeToken = tradeToken as MyCurrency
        // eslint-disable-next-line @typescript-eslint/camelcase
        const { data, last_page }: ListResultType<TradeRecordListItemType[]> = await getTradeRecordList({
          token: (MyToken && MyToken.address) as string,
          tradeToken: (TradeToken && TradeToken.address) as string,
          swapVersion: swapVersion,
          ...params
        })

        if (ignore.current || (data && !Object.keys(data).length) || !data) {
          setLoading(false)
          return
        }
        setParams({
          ...params,
          // eslint-disable-next-line @typescript-eslint/camelcase
          lastPage: last_page
        })
        const currentData = [...lists].concat(data)
        setLists(currentData)
        setLoading(false)
        ignore.current = false
      } catch (e) {
        setLoading(false)
        if (e instanceof Error) {
          console.error(e.message)
        }
      }
    },
    [lists, params, token, tradeToken]
  )
  function loadMore() {
    console.log()
    return new Promise(function(resolve, reject) {
      resolve()
    }).then(function() {
      //模拟ajax请求
      setParams({
        ...params,
        // eslint-disable-next-line @typescript-eslint/camelcase
        page: params.page + 1
      })
    })
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const loadMoreItems = loading ? () => {} : loadMore
  useEffect(() => {
    ignore.current = false
    fetchLists()
    return () => {
      ignore.current = true
    }
  }, [params.page])
  const hasNextPage: boolean = useMemo(() => {
    return params.page < params.lastPage
  }, [params])

  return { lists, params, loadMoreItems, loading, hasNextPage }
}

export function useQuotesDataViewHook({ token, tradeToken }: UseDefaultChartDataType) {
  const [data, setData] = useState<LatestTradeAddressListItem[]>([])
  const ignore = useRef(false)
  useEffect(() => {
    ignore.current = false
    const init = async function() {
      try {
        const MyToken = token as MyCurrency
        const TradeToken = tradeToken as MyCurrency
        const lists = await getLatestTradeAddressList({
          token: (MyToken && MyToken.address) as string,
          tradeToken: (TradeToken && TradeToken.address) as string,
          swapVersion
        })
        if (ignore.current || !lists || !lists.length) {
          return
        }

        setData(lists)
      } catch (e) {
        console.log(e)
      }
    }
    init()
    return () => {
      ignore.current = true
    }
  }, [token, tradeToken])
  return { data }
}
