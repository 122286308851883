import React from 'react'
import QuotesFlex from '../../components/quotes/QuotesFlex'
import { RouteComponentProps } from 'react-router'
import { useCurrency } from '../../hooks/Tokens'
import styled from 'styled-components'
import { QuotesTradeList } from '../../components/quotes/QuotesTradeList'
import QuotesButton from '../../components/quotes/QuotesButton'

const QuotesFlexWrapContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 0;
  display: flex;
  flex-direction: column;
`
export default function Quotes({
  history,
  match: {
    params: { currencyIdA, currencyIdB }
  }
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const [loadedInputCurrency, loadedOutputCurrency] = [useCurrency(currencyIdA), useCurrency(currencyIdB)]

  if (!Boolean(loadedInputCurrency && loadedOutputCurrency)) {
    return null
  }
  function handleLinkTo(type?: string) {
    if (type === 'buy') {
      history.push(`/swap/${currencyIdA}/${currencyIdB}`)
      return
    }
    history.push(`/swap/${currencyIdB}/${currencyIdA}`)
  }
  return (
    <QuotesFlexWrapContainer>
      <QuotesFlex token={loadedInputCurrency!} tradeToken={loadedOutputCurrency!}></QuotesFlex>
      <QuotesTradeList token={loadedInputCurrency!} tradeToken={loadedOutputCurrency!}></QuotesTradeList>
      <QuotesButton onBuy={handleLinkTo}></QuotesButton>
    </QuotesFlexWrapContainer>
  )
}
