import React, { useRef } from 'react'
import styled from 'styled-components'
import { useResponsive } from 'ahooks'
import Banner1 from '../../assets/images/banner1.png'
import Banner2 from '../../assets/images/banner2.png'
import Banner3 from '../../assets/images/banner3.png'
import Banner4 from '../../assets/images/banner4.png'
import PcBanner1 from '../../assets/images/pcBanner1.png'
import PcBanner2 from '../../assets/images/pcBanner2.png'
import PcBanner3 from '../../assets/images/pcBanner3.png'
import PcBanner4 from '../../assets/images/pcBanner4.png'
import LeftIcon from '../../assets/images/left.png'
import RightIcon from '../../assets/images/right.png'
import { ArrowRight } from 'react-feather'
import { ButtonPrimary } from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Swiper } from 'antd-mobile'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
const FeaturedContainer = styled.div<{ isXs: boolean }>`
  width: 100%;
  background: #fafaff;
  padding-top: ${({ isXs }) => (isXs ? '80px' : '180px')};
  padding-bottom: ${({ isXs }) => (isXs ? '80px' : '280px')};
`
const FeaturedContent = styled.div<{ isXs: boolean }>`
  max-width: ${({ isXs }) => (isXs ? '90%' : '1120px')};
  margin: 0 auto;
`
const Text = styled.div<{ isXs: boolean }>`
  color: #141416;
  font-size: ${({ isXs }) => (isXs ? '24px' : '48px')};
  font-weight: 900;
  text-align: center;
`
const FeaturedList = styled.div<{ isXs: boolean }>`
  overflow-x: scroll;
  white-space: nowrap;
  padding-top: 40px;
`
const FeaturedItem = styled.div<{ isXs: boolean }>`
  width: ${({ isXs }) => (isXs ? '90%' : '90%')};
  height: ${({ isXs }) => (isXs ? '410px' : '410px')};
  margin-right: 20px;
  display: inline-block;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
`
const FeaturedDesc = styled.div`
  background: rgba(255, 255, 255, 0.85);
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 92%;
  height: auto;
  border-radius: 0 12px 12px 0;
  white-space: wrap;
  padding: 0 16px 16px 16px;
`
const FeaturedDex = styled.div<{ isXs: boolean }>`
  ${({ isXs }) => (isXs ? `background: url(${Banner4}) no-repeat` : `background: url(${PcBanner4}) no-repeat`)};
  background-size: 100% 100%;
  margin-top: 40px;
  width: 100%;
  height: ${({ isXs }) => (isXs ? '552px' : '352px')};
  padding: ${({ isXs }) => (isXs ? '0 24px' : '0 64px')};
  color: #fff;
`

const RightIconContainer = styled.img`
  position: absolute;
  width: 80px;
  z-index: 1;
  top: 50%;
  right: 23px;
  margin-top: -40px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `}
`
const LeftIconContainer = styled.img`
  position: absolute;
  width: 80px;
  z-index: 1;
  left: 8px;
  top: 50%;
  margin-top: -40px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `}
`

const Featured = () => {
  const responsive = useResponsive()
  const isXs = responsive.xs && !responsive.md
  const { t } = useTranslation()
  const history = useHistory()
  const ref = useRef<SwiperRef>(null)

  const list = [
    {
      id: 1,
      src: isXs ? Banner1 : PcBanner1
    },
    {
      id: 2,
      src: isXs ? Banner2 : PcBanner2
    },
    {
      id: 3,
      src: isXs ? Banner3 : PcBanner3
    }
  ]

  const items = list.map((item, index) => (
    <Swiper.Item key={index} style={{ display: 'flex', justifyContent: 'center' }}>
      <FeaturedItem key={item.id} isXs={isXs}>
        <img style={{ width: '100%' }} src={item.src} />
        <FeaturedDesc>
          <p style={{ color: '#141416', fontSize: 14, fontWeight: 700 }}>
            ALYX Swap adds portfolio manager function in partnership with Bril
          </p>
          <span style={{ color: '#141416', fontSize: 12, opacity: 0.5, fontWeight: 400 }}>
            Racing Car NFTs are divided into four types: N-type, R-type, SR-type, and a new genre known as GameFi are
            divided into ...
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',

              color: '#0100FC',
              fontSize: 12
            }}
          >
            Read More
            <ArrowRight style={{ width: 12, marginLeft: 10 }} />
          </div>
        </FeaturedDesc>
      </FeaturedItem>
    </Swiper.Item>
  ))
  return (
    <FeaturedContainer isXs={isXs}>
      <FeaturedContent isXs={isXs}>
        <Text style={{ paddingBottom: isXs ? 0 : 50 }} isXs={isXs}>
          Featured information
        </Text>
        <FeaturedList isXs={isXs}>
          <Swiper
            ref={ref}
            indicator={(total, current) => {
              return (
                <div className="">
                  <LeftIconContainer
                    onClick={() => {
                      ref.current?.swipeNext()
                    }}
                    src={LeftIcon}
                  />
                  <RightIconContainer
                    onClick={() => {
                      ref.current?.swipePrev()
                    }}
                    src={RightIcon}
                  />
                </div>
              )
            }}
            slideSize={isXs ? 90 : 100}
            loop
            stuckAtBoundary={false}
          >
            {items}
          </Swiper>          
        </FeaturedList>
        <FeaturedDex isXs={isXs}>
          <p
            style={{
              fontSize: isXs ? 24 : 40,
              fontWeight: 600,
              paddingTop: '60px',
              marginBottom: 10,
              maxWidth: isXs ? '100%' : '700px'
            }}
          >
            Join the most popular DEX ecosystem now
          </p>
          <div
            style={{
              fontSize: isXs ? 14 : 20,
              fontWeight: 500,
              color: '#D4D4D4',
              maxWidth: isXs ? '100%' : '630px',
              opacity: isXs ? 0.6 : 1
            }}
          >
            Join us to unlock a new, transparent, global and decentralized wealth code
          </div>
          <ButtonPrimary
            onClick={() => {
              history.push('/swap')
            }}
            style={{height: isXs ? 48 : 'auto',  width: 160, marginTop: 20 }}
          >
            {t('Trade now')}
          </ButtonPrimary>
        </FeaturedDex>
      </FeaturedContent>
    </FeaturedContainer>
  )
}

export default Featured
