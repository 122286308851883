import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { BarPrice, MouseEventParams, SeriesMarker, Time } from 'lightweight-charts'
import KChartVolumeSeries from './KChartVolumeSeries'
import KChartCandleSeries from './KChartCandleSeries'
import KChartLineSeries from './KChartLineSeries'
import {
  calculateBoll,
  calculateKDJ,
  calculateMACD,
  calculateRSi,
  calculateSMA,
  calculateWR,
  filterKlineDataToHL
} from './common'
import { mainIndicator, subIndicator } from './static'
import { bigNumberTransform, formatMoney, formTimeStamp2DateTime3 } from '../../utils/common'
import ChartContainer from './ChartContainer'
import { useTranslation } from 'react-i18next'
import { useDarkModeManager } from '../../state/user/hooks'
import { BigNumber } from 'bignumber.js'
const KLineChartContainer = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  padding-bottom: 30px;

  .sma-legend {
    width: 96px;
    height: 70px;
    position: absolute;
    padding: 8px;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.23);
    text-align: left;
    z-index: 1000;
    pointer-events: none;
  }
`
const KlineIndicatorContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 32px;
  line-height: 32px;
`
const KlineIndicatorItem = styled.div<{ num?: number }>`
  display: inline-flex;
  width: ${({ num }) => {
    if (typeof num === 'undefined') {
      return `calc(100% / 6 * 2)`
    }
    return `calc(100% / 6 * ${num})`
  }};
  align-items: center;
  position: relative;

  &:after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: inline-block;
    width: 1px;
    height: 8px;
    background-color: ${({ theme }) => theme.bg5};
  }

  &:last-child:after {
    display: none;
  }
`
const StyledNavLink = styled.div<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, isActive }) => (isActive ? theme.text1 : theme.text3)};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  font-size: 12px;
  flex: 1;
`

// 交易显示层
const LegendVolume = styled.div<{ top: string }>`
  display: block;
  position: absolute;
  left: 12px;
  top: ${({ top }) => top};
  color: #a3a7aa;
  font-size: 12px;
  line-height: 13px;
  z-index: 1;
  @media (max-width: 721px) {
    font-size: 10px;
  }
`
const LegendMaLine = styled.div<{ top: string; isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  align-items: center;
  position: absolute;
  left: 12px;
  top: ${({ top }) => top};
  font-size: 12px;
  line-height: 13px;
  z-index: 1;
  .legendMaLineItem {
    margin-right: 7px;
    display: inline-flex;
    align-items: center;
  }
  @media (max-width: 721px) {
    font-size: 10px;
  }
`
const LegendTooltip = styled.div`
  position: absolute;
  left: 12px;
  top: 22px;
  background-color: ${({ theme }) => theme.bg1};
  width: 140px;
  min-height: 84px;
  border-radius: 3px;
  border: 1px solid #969696;
  padding: 8px 7px 5px 7px;
  z-index: 2;
  display: none;
  div {
    display: flex;
    align-items: flex-start;
    font-size: 10px;
    justify-content: space-between;
    padding-bottom: 3px;
  }
  span.label {
    font-size: 10px;
    color: #969696;
    color: #717173;
  }
  span.value {
    flex: 1;
    display: inline-flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-width: 70%;
    text-align: right;
  }
  @media (max-width: 721px) {
    top: 18px;
  }
`
export interface ChartData2Type {
  close: number
  high: number
  low: number
  open: number
  timestamp: number
  turnover: number
  volume: number
}
export interface KLineChartProps {
  data: ChartData2Type[]
  updateData: ChartData2Type[]
}

export interface CandleChartType {
  time: number | string
  open: number
  high: number
  low: number
  close: number
}

export interface MyCandleChartType extends CandleChartType {
  volume: number
}

export interface VolumeSeriesDataType {
  time: number | string
  value: number
  color: string
}
interface LineSeriesDataType {
  time: string | number
  value: number
}

function handleCallBackChartData(
  data: ChartData2Type[]
): { volumeData: VolumeSeriesDataType[]; candleData: CandleChartType[] } {
  const volumeData: VolumeSeriesDataType[] = [],
    candleData: CandleChartType[] = []
  for (const item of data) {
    volumeData.push({
      time: item.timestamp / 1000,
      value: item.volume,
      color: item.close - item.open >= 0 ? 'rgba(0, 150, 136, 0.4)' : 'rgba(255,82,82, 0.4)'
    })
    candleData.push({
      time: item.timestamp / 1000,
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close
    })
  }
  return {
    volumeData,
    candleData
  }
}

export default function KLineChart({ data, updateData }: KLineChartProps) {
  const [darkMode] = useDarkModeManager()
  const { t } = useTranslation()

  const [container, setContainer] = useState(false)
  const handleRef = useCallback(ref => setContainer(ref), [])
  const ParentChart = useRef<any>(null)
  const [klineData, setKlineData] = useState<{
    candleSeries: CandleChartType[]
    volumeSeries: VolumeSeriesDataType[]
    rsiSeries: LineSeriesDataType[]
    ma5Series: LineSeriesDataType[]
    ma10Series: LineSeriesDataType[]
    ma30Series: LineSeriesDataType[]
    volMa5Series: LineSeriesDataType[]
    volMa10Series: LineSeriesDataType[]
    bollUpperSeries: LineSeriesDataType[]
    bollMiddleSeries: LineSeriesDataType[]
    bollLowerSeries: LineSeriesDataType[]
    KDJKLineSeries: LineSeriesDataType[]
    KDJDLineSeries: LineSeriesDataType[]
    KDJJLineSeries: LineSeriesDataType[]
    WRKlineSeries: LineSeriesDataType[]
    MACDlineSeries: VolumeSeriesDataType[]
    DIFlineSeries: LineSeriesDataType[]
    DEAlineSeries: LineSeriesDataType[]
  }>({
    // 蜡烛 图
    candleSeries: [],
    // 交易量
    volumeSeries: [],
    // rsi 系列
    rsiSeries: [],
    // MA 蜡烛图上的 直线图
    ma5Series: [],
    ma10Series: [],
    ma30Series: [],
    //   交易量 上的MA线
    volMa5Series: [],
    volMa10Series: [],
    //   Boll
    bollUpperSeries: [],
    bollMiddleSeries: [],
    bollLowerSeries: [],
    //   kdj
    KDJKLineSeries: [],
    KDJDLineSeries: [],
    KDJJLineSeries: [],
    // WR
    WRKlineSeries: [],
    //   MACD  DIF DEA
    MACDlineSeries: [],
    DIFlineSeries: [],
    DEAlineSeries: []
  })
  // 蜡烛图 单独数据
  const candleDataRef = useRef<CandleChartType[]>([])
  // 交易量 缓存数据
  const volumeDataRef = useRef<VolumeSeriesDataType[]>([])
  //  蜡烛图
  const candleSeries = useRef<any>(null)
  //  交易量
  const volumeSeries = useRef<any>(null)
  //  RSI
  const RsiLineSeries = useRef<any>(null)
  // 蜡烛图上的 MA线
  const ma5LineSeries = useRef<any>(null)
  const ma10LineSeriesRef = useRef<any>(null)
  const ma30LineSeriesRef = useRef<any>(null)
  // 交易量 上的 MA线
  const volMa5LineSeries = useRef<any>(null)
  const volMa10LineSeriesRef = useRef<any>(null)
  // BOLL 线 Ref
  const bollUpperSeries = useRef<any>(null)
  const bollMiddleSeries = useRef<any>(null)
  const bollLowerSeries = useRef<any>(null)
  // kdj 三线
  const KDJKLineSeries = useRef<any>(null)
  const KDJDLineSeries = useRef<any>(null)
  const KDJJLineSeries = useRef<any>(null)
  // WR
  const WRLineSeries = useRef<any>(null)
  // MACD
  const MACDlineSeries = useRef<any>(null)
  const DIFlineSeries = useRef<any>(null)
  const DEAlineSeries = useRef<any>(null)
  const [params, setParams] = useState<{ main: string; sub: string }>({
    main: 'MA',
    sub: ''
  })
  const paramsRef = useRef<{ main: string; sub: string }>(params)
  // 指标 显示 节点
  const LegendMaLineRef = useRef<any>(null)
  const LegendVolLineRef = useRef<any>(null)
  const LegendSubRef = useRef<any>(null)
  const LegendTooltipRef = useRef<any>(null)
  const subscribeCrosshairMoveFunction = useCallback(
    function(param: MouseEventParams, width: number) {
      try {
        if (param.point === undefined || !param.time) {
          LegendTooltipRef.current.style.display = 'none'
          return
        }
        const volumeSeriesData = param.seriesData.get(volumeSeries.current) as { value: number }
        const volPrice =
          volumeSeriesData && volumeSeriesData.value ? formatMoney(volumeSeriesData.value, { decimalPlaces: 2 }) : '∞'
        LegendTooltipRef.current.style.display = 'block'
        const currentData = param.seriesData.get(candleSeries.current) as CandleChartType
        if (currentData && Object.keys(currentData).length) {
          const volume = formatMoney(currentData.close - currentData.open, { decimalPlaces: 6 })
          const volumePercent = new BigNumber(currentData.close)
            .minus(new BigNumber(currentData.open))
            .dividedBy(new BigNumber(currentData.open))
            .multipliedBy(100)
            .decimalPlaces(2, BigNumber.ROUND_DOWN)
            .toString()
          LegendTooltipRef.current.innerHTML = `<div><span class="label">${t(
            '时间'
          )}</span><span class="value">${formTimeStamp2DateTime3(
            currentData.time
          )}</span></div><div><span class="label">${t('开盘')}</span><span class="value">${
            currentData.open
          }</span></div><div><span class="label">${t('最高')}</span><span class="value">${
            currentData.high
          }</span></div><div><span class="label">${t('最低')}</span><span class="value">${
            currentData.low
          }</span></div><div><span class="label">${t('收盘')}</span><span class="value">${
            currentData.close
          }</span></div><div><span class="label">${t('涨跌额')}</span><span class="value" style="color:${
            currentData.close - currentData.open >= 0 ? '#5bb89b' : '#d36973'
          }">${volume}</span></div> 
            <div><span class="label">${t('涨跌幅')}</span><span class="value" style="color:${
            currentData.close - currentData.open >= 0 ? '#5bb89b' : '#d36973'
          }">${volumePercent}%</span></div> 
            <div><span class="label">${t('成交量')}</span><span class="value">${
            volumeSeriesData && volumeSeriesData.value ? volumeSeriesData.value.toFixed(2) : '∞'
          }</span></div> 
          `
          if (param.point.x >= width / 2) {
            LegendTooltipRef.current.style.left = '10px'
            LegendTooltipRef.current.style.right = 'initial'
          } else {
            LegendTooltipRef.current.style.right = '10px'
            LegendTooltipRef.current.style.left = 'initial'
          }
        }

        LegendVolLineRef.current.innerHTML = `VOL:${volPrice}`
        if (paramsRef.current.main === 'MA') {
          const ma5LineSeriesData = param.seriesData.get(ma5LineSeries.current) as { value: number }
          const ma5Price =
            ma5LineSeriesData && ma5LineSeriesData.value
              ? formatMoney(ma5LineSeriesData.value, { decimalPlaces: 6 })
              : '∞'

          const ma10LineSeriesData = param.seriesData.get(ma10LineSeriesRef.current) as { value: number }
          const ma10Price =
            ma10LineSeriesData && ma10LineSeriesData.value
              ? formatMoney(ma10LineSeriesData.value, { decimalPlaces: 6 })
              : '∞'

          const ma30LineSeriesData = param.seriesData.get(ma30LineSeriesRef.current) as { value: number }
          const ma30Price =
            ma30LineSeriesData && ma30LineSeriesData.value
              ? formatMoney(ma30LineSeriesData.value, { decimalPlaces: 6 })
              : '∞'
          LegendMaLineRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#f1b500">MA5:${ma5Price}</div><div class="legendMaLineItem" style="color:#fe549a" >MA10:${ma10Price}</div><div class="legendMaLineItem" style="color:#0279d3" >MA30:${ma30Price}</div>`
        }
        if (paramsRef.current.main === 'BOLL') {
          const bollMiddleSeriesData = param.seriesData.get(bollMiddleSeries.current) as { value: number }
          const middlePrice =
            bollMiddleSeriesData && bollMiddleSeriesData.value
              ? formatMoney(bollMiddleSeriesData.value, { decimalPlaces: 6 })
              : '∞'

          const bollUpperSeriesData = param.seriesData.get(bollUpperSeries.current) as { value: number }
          const bollUpperPrice =
            bollUpperSeriesData && bollUpperSeriesData.value
              ? formatMoney(bollUpperSeriesData.value, { decimalPlaces: 6 })
              : '∞'

          const bollLowerSeriesData = param.seriesData.get(bollLowerSeries.current) as { value: number }
          const bollLowerPrice =
            bollLowerSeriesData && bollLowerSeriesData.value
              ? formatMoney(bollLowerSeriesData.value, { decimalPlaces: 6 })
              : '∞'
          LegendMaLineRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#fe549a" >UP:${bollUpperPrice}</div><div class="legendMaLineItem" style="color:#f1b500">BOLL:${middlePrice}</div><div class="legendMaLineItem" style="color:#0279d3" >LB:${bollLowerPrice}</div>`
        }
        if (paramsRef.current.sub === 'MACD') {
          const macdSeriesData = param.seriesData.get(MACDlineSeries.current) as { value: number }
          const macdSeriesPrice =
            macdSeriesData && macdSeriesData.value ? formatMoney(macdSeriesData.value, { decimalPlaces: 6 }) : '∞'

          const DIFlineSeriesData = param.seriesData.get(DIFlineSeries.current) as { value: number }
          const DIFlineSeriesPrice =
            DIFlineSeriesData && DIFlineSeriesData.value
              ? formatMoney(DIFlineSeriesData.value, { decimalPlaces: 6 })
              : '∞'

          const DEAlineSeriesData = param.seriesData.get(DEAlineSeries.current) as { value: number }
          const DEAlineSeriesPrice =
            DEAlineSeriesData && DEAlineSeriesData.value
              ? formatMoney(DEAlineSeriesData.value, { decimalPlaces: 6 })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#a3a7aa">MACD(12,26,9):${macdSeriesPrice}</div><div class="legendMaLineItem" style="color:#bab595">DIF:${DIFlineSeriesPrice}</div><div class="legendMaLineItem" style="color:#fe549a" >DEA:${DEAlineSeriesPrice}</div>`
        }
        if (paramsRef.current.sub === 'KDJ') {
          const KDJKLineSeriesData = param.seriesData.get(KDJKLineSeries.current) as { value: number }
          const KDJKPrice =
            KDJKLineSeriesData && KDJKLineSeriesData.value
              ? formatMoney(KDJKLineSeriesData.value, { decimalPlaces: 6 })
              : '∞'

          const KDJDLineSeriesData = param.seriesData.get(KDJDLineSeries.current) as { value: number }
          const KDJDPrice =
            KDJDLineSeriesData && KDJDLineSeriesData.value
              ? formatMoney(KDJDLineSeriesData.value, { decimalPlaces: 6 })
              : '∞'

          const KDJJLineSeriesData = param.seriesData.get(KDJJLineSeries.current) as { value: number }
          const KDJJPrice =
            KDJJLineSeriesData && KDJJLineSeriesData.value
              ? formatMoney(KDJJLineSeriesData.value, { decimalPlaces: 6 })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#a3a7aa">KDJ(14,1,3)</div><div class="legendMaLineItem" style="color:#bab595">K:${KDJKPrice}</div><div class="legendMaLineItem" style="color:#fe549a" >D:${KDJDPrice}</div><div class="legendMaLineItem" style="color:#0279d3" >J:${KDJJPrice}</div>
        `
        }

        if (paramsRef.current.sub === 'RSI') {
          const RsiLineSeriesData = param.seriesData.get(RsiLineSeries.current) as { value: number }
          const rsiLineSeriesPrice =
            RsiLineSeriesData && RsiLineSeriesData.value
              ? formatMoney(RsiLineSeriesData.value, { decimalPlaces: 6 })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#c3b17f">RSI(14):${rsiLineSeriesPrice}</div>`
        }
        if (paramsRef.current.sub === 'WR') {
          const WrLineSeriesData = param.seriesData.get(WRLineSeries.current) as { value: number }
          const wrLineSeriesPrice =
            WrLineSeriesData && WrLineSeriesData.value ? formatMoney(WrLineSeriesData.value, { decimalPlaces: 6 }) : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#c3b17f">WR(14):${wrLineSeriesPrice}</div>
          `
        }
      } catch (e) {
        console.log(e)
      }
    },
    [t]
  )

  const onSubscribeVisibleLogicalRangeChange = useCallback(
    ({ from, to }: { from: number; to: number }, width: number) => {
      if (!candleDataRef.current.length) {
        return
      }
      const { maxHigh, maxHighTime, minLow, minLowTime } = filterKlineDataToHL(candleDataRef.current, from, to)
      if (!maxHigh) {
        return
      }
      const upPosition = ParentChart.current && ParentChart.current.timeScale().timeToCoordinate(maxHighTime)
      const downPosition = ParentChart.current && ParentChart.current.timeScale().timeToCoordinate(minLowTime)
      const markersData: SeriesMarker<Time>[] = [
        {
          time: maxHighTime as Time,
          position: 'aboveBar',
          shape: 'arrowDown',
          color: darkMode ? '#a2a2a4' : '#545452',
          id: 'up',
          text:
            (upPosition < width / 3 ? `                ` : '') +
            bigNumberTransform(maxHigh, { decimalPlaces: 4 }) +
            (upPosition > (width / 3) * 2 ? `                ` : '')
        },
        {
          time: minLowTime as Time,
          position: 'belowBar',
          shape: 'arrowUp',
          color: darkMode ? '#a2a2a4' : '#545452',
          id: 'down',
          text:
            (downPosition < width / 3 ? `                ` : '') +
            bigNumberTransform(minLow!, { decimalPlaces: 4 }) +
            (downPosition > (width / 3) * 2 ? `                ` : '')
        }
      ]
      candleSeries.current.setMarkers(markersData)
    },
    [candleSeries, candleDataRef, darkMode]
  )

  useEffect(() => {
    if (!data.length) {
      return
    }
    // 交易量
    const {
      volumeData,
      candleData
    }: {
      volumeData: VolumeSeriesDataType[]
      candleData: CandleChartType[]
    } = handleCallBackChartData(data)
    // rsi 数据
    const rsiSeriesData = calculateRSi(candleData, 14)
    // 计算 MA 5 10
    const candiMa5Value = calculateSMA(candleData, 5)
    const candiMa10Value = calculateSMA(candleData, 10)
    const candiMa30Value = calculateSMA(candleData, 30)
    // 交易量上的 MA线
    const volMa5Value = calculateSMA(volumeData, 5, true)
    const volMa10Value = calculateSMA(volumeData, 10, true)
    // BOLL 线 middle, upper, lower
    const { middle, upper, lower } = calculateBoll(candleData)
    // 计算 jdk线
    const { k, d, j } = calculateKDJ(candleData, 14, 1, 3)
    // wr
    const wrData = calculateWR(candleData)
    // macd
    const { dif, dea, macd } = calculateMACD(candleData)
    candleDataRef.current = candleData
    volumeDataRef.current = volumeData
    setKlineData({
      // 蜡烛 图
      candleSeries: candleData,
      // 交易量
      volumeSeries: volumeData,
      // rsi 系列
      rsiSeries: rsiSeriesData,
      // MA 蜡烛图上的 直线图
      ma5Series: candiMa5Value,
      ma10Series: candiMa10Value,
      ma30Series: candiMa30Value,
      // 交易量上的 MA 线
      volMa5Series: volMa5Value,
      volMa10Series: volMa10Value,
      //   Boll
      bollUpperSeries: upper,
      bollMiddleSeries: middle,
      bollLowerSeries: lower,
      //   kdj
      KDJKLineSeries: k,
      KDJDLineSeries: d,
      KDJJLineSeries: j,
      //   WR
      WRKlineSeries: wrData,
      //   MACD DIF DEA
      MACDlineSeries: macd,
      DIFlineSeries: dif,
      DEAlineSeries: dea
    })
    // 夫
    ParentChart.current &&
      ParentChart.current.timeScale().applyOptions({
        rightOffset: 0
      })
  }, [data])
  useEffect(() => {
    if (!LegendVolLineRef.current || !klineData.volumeSeries.length) {
      return
    }
    try {
      //   初始化 第一个
      // 交易量
      const volPrice = formatMoney(klineData.volumeSeries[klineData.volumeSeries.length - 1].value, {
        decimalPlaces: 2
      })
      LegendVolLineRef.current.innerHTML = `VOL:${volPrice}`
      // PM2
      if (params.main === 'MA') {
        const ma5Price =
          klineData.ma5Series.length > 0
            ? formatMoney(klineData.ma5Series[klineData.ma5Series.length - 1].value, { decimalPlaces: 6 })
            : '∞'
        const ma10Price =
          klineData.ma10Series.length > 0
            ? formatMoney(klineData.ma10Series[klineData.ma10Series.length - 1].value, { decimalPlaces: 6 })
            : '∞'
        const ma30Price =
          klineData.ma30Series.length > 0
            ? formatMoney(klineData.ma30Series[klineData.ma30Series.length - 1].value, { decimalPlaces: 6 })
            : '∞'
        LegendMaLineRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#f1b500">MA5:${ma5Price}</div><div class="legendMaLineItem" style="color:#fe549a" >MA10:${ma10Price}</div><div class="legendMaLineItem" style="color:#0279d3" >MA30:${ma30Price}</div>`
      }
      if (params.main === 'BOLL') {
        const middlePrice =
          klineData.bollMiddleSeries.length > 0
            ? formatMoney(klineData.bollMiddleSeries[klineData.bollMiddleSeries.length - 1].value, {
                decimalPlaces: 6
              })
            : '∞'

        const bollUpperPrice =
          klineData.bollUpperSeries.length > 0
            ? formatMoney(klineData.bollUpperSeries[klineData.bollUpperSeries.length - 1].value, {
                decimalPlaces: 6
              })
            : '∞'

        const bollLowerPrice =
          klineData.bollLowerSeries.length > 0
            ? formatMoney(klineData.bollLowerSeries[klineData.bollLowerSeries.length - 1].value, {
                decimalPlaces: 6
              })
            : '∞'
        LegendMaLineRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#fe549a" >UP:${bollUpperPrice}</div><div class="legendMaLineItem" style="color:#f1b500">BOLL:${middlePrice}</div><div class="legendMaLineItem" style="color:#0279d3" >LB:${bollLowerPrice}</div>`
      }
      if (params.sub) {
        if (params.sub === 'KDJ') {
          const KDJKPrice =
            klineData.KDJKLineSeries.length > 0
              ? formatMoney(klineData.KDJKLineSeries[klineData.KDJKLineSeries.length - 1].value, {
                  decimalPlaces: 6
                })
              : '∞'

          const KDJDPrice =
            klineData.KDJKLineSeries.length > 0
              ? formatMoney(klineData.KDJDLineSeries[klineData.KDJDLineSeries.length - 1].value, {
                  decimalPlaces: 6
                })
              : '∞'

          const KDJJPrice =
            klineData.KDJKLineSeries.length > 0
              ? formatMoney(klineData.KDJJLineSeries[klineData.KDJJLineSeries.length - 1].value, {
                  decimalPlaces: 6
                })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#a3a7aa">KDJ(14,1,3)</div><div class="legendMaLineItem" style="color:#bab595">K:${KDJKPrice}</div><div class="legendMaLineItem" style="color:#fe549a" >D:${KDJDPrice}</div><div class="legendMaLineItem" style="color:#0279d3" >J:${KDJJPrice}</div>`
        }

        if (params.sub === 'RSI') {
          const rsiLineSeriesPrice =
            klineData.rsiSeries.length > 0
              ? formatMoney(klineData.rsiSeries[klineData.rsiSeries.length - 1].value, {
                  decimalPlaces: 6
                })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#c3b17f">RSI(14):${rsiLineSeriesPrice}</div>`
        }
        if (params.sub === 'WR') {
          const wrLineSeriesPrice =
            klineData.WRKlineSeries.length > 0
              ? formatMoney(klineData.WRKlineSeries[klineData.WRKlineSeries.length - 1].value, {
                  decimalPlaces: 6
                })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#c3b17f">WR(14):${wrLineSeriesPrice}</div>`
        }

        if (paramsRef.current.sub === 'MACD') {
          const macdSeriesPrice =
            klineData.MACDlineSeries.length > 0
              ? formatMoney(klineData.MACDlineSeries[klineData.MACDlineSeries.length - 1].value, { decimalPlaces: 6 })
              : '∞'

          const DIFlineSeriesPrice =
            klineData.DIFlineSeries.length > 0
              ? formatMoney(klineData.DIFlineSeries[klineData.DIFlineSeries.length - 1].value, { decimalPlaces: 6 })
              : '∞'

          const DEAlineSeriesPrice =
            klineData.DEAlineSeries.length > 0
              ? formatMoney(klineData.DEAlineSeries[klineData.DEAlineSeries.length - 1].value, { decimalPlaces: 6 })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#a3a7aa">MACD(12,26,9):${macdSeriesPrice}</div><div class="legendMaLineItem" style="color:#bab595">DIF:${DIFlineSeriesPrice}</div><div class="legendMaLineItem" style="color:#fe549a" >DEA:${DEAlineSeriesPrice}</div>`
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [klineData, params.main, params.sub])
  useEffect(() => {
    if (!updateData || !updateData.length) {
      return
    }
    // 交易量
    const {
      volumeData,
      candleData
    }: {
      volumeData: VolumeSeriesDataType[]
      candleData: CandleChartType[]
    } = handleCallBackChartData(updateData)
    //  最新缓存的 数据 加1
    // 蜡烛图 单独数据
    candleDataRef.current.push(candleData[candleData.length - 1])
    volumeDataRef.current.push(volumeData[volumeData.length - 1])
    // // rsi 数据
    const rsiSeriesData = calculateRSi(candleDataRef.current, 14)
    // // 计算 MA 5 10
    const candiMa5Value = calculateSMA(candleDataRef.current, 5)
    const candiMa10Value = calculateSMA(candleDataRef.current, 10)
    const candiMa30Value = calculateSMA(candleDataRef.current, 30)
    // // 交易量上的 MA线
    const volMa5Value = calculateSMA(volumeDataRef.current, 5, true)
    const volMa10Value = calculateSMA(volumeDataRef.current, 10, true)
    // // BOLL 线 middle, upper, lower
    const { middle, upper, lower } = calculateBoll(candleDataRef.current)
    // // 计算 jdk线
    const { k, d, j } = calculateKDJ(candleDataRef.current, 14, 1, 3)
    // // wr
    const wrData = calculateWR(candleDataRef.current)
    // // macd
    const { dif, dea, macd } = calculateMACD(candleDataRef.current)

    //  蜡烛图 只有他们 是更新 一条数据
    candleSeries.current.update(candleData[candleData.length - 1])
    //  交易量 只有他们 是更新 一条数据
    volumeSeries.current.update(volumeData[volumeData.length - 1])
    // 以下 重新计算才能 产生平均
    //  RSI
    RsiLineSeries.current.setData(rsiSeriesData)
    // 蜡烛图上的 MA线
    ma5LineSeries.current.setData(candiMa5Value)
    ma10LineSeriesRef.current.setData(candiMa10Value)
    ma30LineSeriesRef.current.setData(candiMa30Value)
    // 交易量 上的 MA线
    volMa5LineSeries.current.setData(volMa5Value)
    volMa10LineSeriesRef.current.setData(volMa10Value)
    // BOLL 线 Ref
    bollUpperSeries.current.setData(upper)
    bollMiddleSeries.current.setData(middle)
    bollLowerSeries.current.setData(lower)
    // kdj 三线
    KDJKLineSeries.current.setData(k)
    KDJDLineSeries.current.setData(d)
    KDJJLineSeries.current.setData(j)
    // WR
    WRLineSeries.current.setData(wrData)
    // MACD
    MACDlineSeries.current.setData(macd)
    DIFlineSeries.current.setData(dif)
    DEAlineSeries.current.setData(dea)
  }, [updateData, klineData])

  return (
    <KLineChartContainer>
      <div ref={handleRef}>
        {container && (
          <ChartContainer
            height={410}
            indcatemain={params.main}
            onSubscribeCrosshairMove={subscribeCrosshairMoveFunction}
            onSubscribeVisibleLogicalRangeChange={onSubscribeVisibleLogicalRangeChange}
            ref={ParentChart}
            container={container}
            overlayPriceScales={params.sub.length > 0 ? {} : {}}
          >
            {/* 蜡烛图 默认 是 right */}
            <KChartCandleSeries
              scaleMargins={
                params.sub.length > 0
                  ? {
                      top: 0.1,
                      bottom: 0.35
                    }
                  : {
                      top: 0.1,
                      bottom: 0.3
                    }
              }
              ref={candleSeries}
              data={klineData.candleSeries}
              priceFormat={{
                type: 'custom',
                formatter: (priceValue: BarPrice) => {
                  return new BigNumber(priceValue).decimalPlaces(3, BigNumber.ROUND_DOWN).toNumber()
                },
                minMove: 0.0005
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue -= 0.001
                  res.priceRange.maxValue += 0.001
                }
                return res
              }}
            />
            {/*Boll Upper 线*/}
            <KChartLineSeries
              ref={bollUpperSeries}
              data={klineData.bollUpperSeries}
              color={'#fe549a'}
              visible={params.main === 'BOLL'}
              priceScaleId={'right'}
            />
            {/*Boll Middle 线*/}
            <KChartLineSeries
              ref={bollMiddleSeries}
              data={klineData.bollMiddleSeries}
              color={'#f1b500'}
              visible={params.main === 'BOLL'}
              priceScaleId={'right'}
            />
            {/*Boll Lower 线*/}
            <KChartLineSeries
              ref={bollLowerSeries}
              data={klineData.bollLowerSeries}
              color={'#0279d3'}
              visible={params.main === 'BOLL'}
              priceScaleId={'right'}
            />
            {/*Boll end 线*/}
            {/*MA 5 线 */}
            <KChartLineSeries
              ref={ma5LineSeries}
              data={klineData.ma5Series}
              color={'#f1b500'}
              visible={params.main === 'MA'}
              priceScaleId={'right'}
            />
            {/*color={'#fe549a'}*/}
            {/*MA 10 线 */}
            <KChartLineSeries
              visible={params.main === 'MA'}
              ref={ma10LineSeriesRef}
              data={klineData.ma10Series}
              color={'#fe549a'}
              priceScaleId={'right'}
            />
            {/*MA 30 线 */}
            <KChartLineSeries
              visible={params.main === 'MA'}
              ref={ma30LineSeriesRef}
              data={klineData.ma30Series}
              color={'#0279d3'}
              priceScaleId={'right'}
            />

            {/*交易额 */}
            {/*MA 5 线 */}
            <KChartLineSeries
              ref={volMa5LineSeries}
              data={klineData.volMa5Series}
              color={'#f1b500'}
              priceScaleId={'volume'}
            />
            {/*color={'#fe549a'}*/}
            {/*MA 10 线 */}
            <KChartLineSeries
              ref={volMa10LineSeriesRef}
              data={klineData.volMa10Series}
              color={'#fe549a'}
              priceScaleId={'volume'}
            />
            <KChartVolumeSeries
              ref={volumeSeries}
              data={klineData.volumeSeries}
              priceFormat={{
                type: 'volume',
                precision: 3,
                minMove: 0.001
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue = 0
                  res.priceRange.maxValue += 0.001
                }

                return res
              }}
              scaleMargins={
                params.sub === ''
                  ? {
                      top: 0.85,
                      bottom: 0
                    }
                  : {
                      top: 0.6,
                      bottom: 0.25
                    }
              }
              color={'#26a69a'}
              priceScaleId={'volume'}
            />
            {/* RSI交易额 */}
            <KChartLineSeries
              ref={RsiLineSeries}
              visible={params.sub === 'RSI'}
              data={klineData.rsiSeries}
              scaleMargins={{
                top: 0.8,
                bottom: 0
              }}
              color={'#c3b17f'}
              priceScaleId={'sub'}
            />
            {/* KDJ  K :#8d82ba  D: #bab595 J:#8aa5a0 */}
            <KChartLineSeries
              ref={KDJKLineSeries}
              visible={params.sub === 'KDJ'}
              data={klineData.KDJKLineSeries}
              scaleMargins={{
                top: 0.8,
                bottom: 0
              }}
              color={'#bab595'}
              priceScaleId={'sub'}
            />
            <KChartLineSeries
              ref={KDJDLineSeries}
              visible={params.sub === 'KDJ'}
              data={klineData.KDJDLineSeries}
              scaleMargins={{
                top: 0.8,
                bottom: 0
              }}
              color={'#8aa5a0'}
              priceScaleId={'sub'}
            />
            <KChartLineSeries
              ref={KDJJLineSeries}
              visible={params.sub === 'KDJ'}
              data={klineData.KDJJLineSeries}
              scaleMargins={{
                top: 0.8,
                bottom: 0
              }}
              color={'#8d82ba'}
              priceScaleId={'sub'}
            />
            {/*  WR 线*/}
            <KChartLineSeries
              ref={WRLineSeries}
              visible={params.sub === 'WR'}
              data={klineData.WRKlineSeries}
              scaleMargins={{
                top: 0.8,
                bottom: 0
              }}
              color={'#c3b17f'}
              priceScaleId={'sub'}
            />
            {/*  macd 线*/}
            <KChartVolumeSeries
              visible={params.sub === 'MACD'}
              ref={MACDlineSeries}
              data={klineData.MACDlineSeries}
              scaleMargins={{
                top: 0.85,
                bottom: 0
              }}
              priceFormat={{
                type: 'volume',
                precision: 8,
                minMove: 0.00000001
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue -= 0.00000001
                  res.priceRange.maxValue += 0.00000001
                }

                return res
              }}
              color={'#26a69a'}
              priceScaleId={'sub'}
            />
            {/*  DIF 线*/}
            <KChartLineSeries
              ref={DIFlineSeries}
              visible={params.sub === 'MACD'}
              data={klineData.DIFlineSeries}
              scaleMargins={{
                top: 0.85,
                bottom: 0
              }}
              priceFormat={{
                type: 'volume',
                precision: 8,
                minMove: 0.00000001
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue -= 0.00000001
                  res.priceRange.maxValue += 0.00000001
                }

                return res
              }}
              color={'#bfb88c'}
              priceScaleId={'sub'}
            />
            {/*  DEA 线*/}
            <KChartLineSeries
              ref={DEAlineSeries}
              visible={params.sub === 'MACD'}
              data={klineData.DEAlineSeries}
              scaleMargins={{
                top: 0.85,
                bottom: 0
              }}
              priceFormat={{
                type: 'volume',
                precision: 8,
                minMove: 0.00000001
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue -= 0.00000001
                  res.priceRange.maxValue += 0.00000001
                }

                return res
              }}
              color={'#84a89e'}
              priceScaleId={'sub'}
            />
          </ChartContainer>
        )}
      </div>
      <LegendMaLine isShow={true} ref={LegendMaLineRef} top={'0px'}></LegendMaLine>
      <LegendVolume
        ref={LegendVolLineRef}
        top={params.sub.length > 0 ? 410 * 0.58 - 30 + 'px' : 410 * 0.75 - 30 + 'px'}
      ></LegendVolume>
      <LegendMaLine ref={LegendSubRef} isShow={params.sub.length > 0} top={410 * 0.79 - 30 + 'px'}></LegendMaLine>
      <LegendTooltip ref={LegendTooltipRef}></LegendTooltip>
      <KlineIndicatorContainer>
        <KlineIndicatorItem>
          {mainIndicator.map(item => (
            <StyledNavLink
              isActive={params.main === item}
              onClick={() => {
                paramsRef.current = { ...params, main: item }
                setParams({ ...params, main: item })
              }}
              key={item}
            >
              {item}
            </StyledNavLink>
          ))}
        </KlineIndicatorItem>
        <KlineIndicatorItem num={subIndicator.length}>
          {subIndicator.map(item => (
            <StyledNavLink
              isActive={params.sub.includes(item)}
              key={item}
              onClick={() => {
                if (params.sub === item) {
                  paramsRef.current = { ...params, sub: '' }
                  setParams({ ...params, sub: '' })
                  return
                }
                paramsRef.current = { ...params, sub: item }
                setParams({ ...params, sub: item })
              }}
            >
              {item}
            </StyledNavLink>
          ))}
        </KlineIndicatorItem>
      </KlineIndicatorContainer>
    </KLineChartContainer>
  )
}
