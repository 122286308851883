import React from 'react'
import SettingSvg from '../../assets/svg/setting.svg'
import styled from 'styled-components'
import RecordSvg from '../../assets/svg/record.svg'
import RefeshSvg from '../../assets/svg/refesh.svg'
import ChartIcon from '../../assets/svg/charts.svg'
import ChartCloseIcon from '../../assets/svg/charts-close.svg'
import Settings from '../../components/Settings'
import { useWalletModalToggle } from '../../state/application/hooks'

const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px dashed #e5e5e5;
  padding-bottom: 30px;
  margin-bottom: 30px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-bottom: 20px;
    margin-bottom: 20px;
  `}
`
const OptionsItem = styled.div`
  margin-left: 10px;
  cursor: pointer;
  position: relative;
`

const OptionsIcon = ({
  isShowKLineChart,
  setIsShowKLineChart,
  showKLine = false,
  setShowLock,
  showLock
}: {
  isShowKLineChart?: any
  setIsShowKLineChart?: any
  showKLine?: any
  setShowLock?: any
  showLock?: any
}) => {
  const toggleWalletModal = useWalletModalToggle()
  return (
    <OptionsWrapper>
      {showKLine && (
        <OptionsItem
          onClick={() => {
            // setIsShowKLineChart(!isShowKLineChart)
            setShowLock(!showLock)
          }}
        >
          <img src={isShowKLineChart && showLock ? ChartIcon : ChartCloseIcon} alt="" />
        </OptionsItem>
      )}
      <OptionsItem onClick={toggleWalletModal}>
        <img src={RecordSvg} alt="" />
      </OptionsItem>
      <OptionsItem>
        <img src={SettingSvg} alt="" />
        <div
          style={{
            position: 'absolute',
            top: '-8px',
            left: '-12px',
            opacity: '0'
          }}
        >
          <Settings />
        </div>
      </OptionsItem>
      <OptionsItem>
        <img src={RefeshSvg} alt="" />
      </OptionsItem>
    </OptionsWrapper>
  )
}

export default OptionsIcon
