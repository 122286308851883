import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { QuotesFlexWrapProps } from './QuotesFlex'
import { useIntroduceViewHook, useQuotesTradeListHook } from './hook/QuotesTradeListHook'
import QuotesListComponent from './QuotesList'
import CurrencyLogo from '../CurrencyLogo'
import { bigNumberTransform, formatMoney } from '../../utils/common'
import { MyCurrency } from '../KLineChart/hook2'
import useDataApi from '../../api'
import { CoinObjectInfoDetail, getCoinExTypeData } from '../../api/swap/klineData'
import { shortenAddress } from '../../utils'
import Copy from '../../components/AccountDetails/Copy'
import { BigNumber } from 'bignumber.js'
import QuotesDataView from './QuotesDataView'
const QuotesTradeListWContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 10px;
  min-height: 600px;
  box-sizing: border-box;
  padding-bottom: 40px;
  height: 100%;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`
const QuotesTradeListWTitleContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  padding: 10px 16px;
  align-items: center;
  background: ${({ theme }) => theme.bg1};
  border-bottom: 1px solid ${({ theme }) => theme.border1};
`
const QuotesTradeListWTitle = styled.h2<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? theme.yellow2 : theme.text1)};
  font-size: ${({ isActive }) => (!isActive ? '14px' : '15px')};
  width: 100%;
  max-width: 80px;
  align-items: center;
  font-weight: ${({ isActive }) => (!isActive ? 'lighter' : 'bold')};
  margin: 0;
  display: inline-flex;
`
const QuotesTradeListUl = styled.ul`
  color: ${({ theme }) => theme.yellow2};
  display: flex;
  padding-left: 10px;
  padding-right: 26px;
  outline-style: none;
  justify-content: space-between;
  margin: 0;
`
const QuotesTradeListLi = styled.li<{ textAlign?: string }>`
  color: ${({ theme }) => theme.yellow2};
  list-style: none;
  flex: 1;
  font-size: 13px;
  padding: 12px 0;
  text-align: ${({ textAlign }) => {
    if (typeof textAlign === 'undefined') {
      return 'left'
    }
    return textAlign
  }};
`
const PoolViewContainer = styled.div`
  padding: 0 10px 10px 10px;
  margin-top: 10px;
`
const PoolViewItem = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.bg1};
`
const PoolViewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  padding-top: 10px;
`
const PoolViewHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`
const PoolViewHeaderRight = styled.div`
  padding: 10px;
  border-radius: 10px;
`
const PoolViewHeaderTitle = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 25px;
`
const PoolViewHeaderDesc = styled.div`
  font-size: 12px;
  color: #8d9194;
  span {
    color: ${({ theme }) => theme.text1};
  }
`

const PoolViewContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const PoolViewContentItem = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  p {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin: 5px 0;
    font-weight: bolder;
  }
  em {
    font-style: normal;
    margin-left: 8px;
  }
  span {
    font-weight: bold;
    font-size: 16px;
  }
`

const IntroduceViewItem = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.bg1};
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  font-size: 12px;
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  padding: 0 16px 7px;
  p {
    padding: 15px 0;
    margin: 0;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
  .source {
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 7px;
    img {
      display: block;
      width: 35px;
      height: 35px;
    }
  }
`
const IntroduceViewBtn = styled.span`
  display: inline-block;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.bg6};
  padding: 9px 22px;
  font-size: 10px;
  text-align: center;
  margin-right: 7px;
  margin-bottom: 7px;
`

const IntroduceViewItemLabel = styled.span`
  color: ${({ theme }) => theme.text8};
`
const IntroduceViewItemValue = styled.span`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  span button {
    padding: 0;
  }
`
function PoolView({ token, tradeToken }: QuotesFlexWrapProps) {
  const MyToken = token as MyCurrency
  const TradeToken = tradeToken as MyCurrency
  const [{ data: coinInfo }] = useDataApi({
    requestFunction: getCoinExTypeData,
    params: {
      token: (MyToken && MyToken!.address) as string,
      tradeToken: (TradeToken && TradeToken!.address) as string
    },
    initData: {}
  })
  const reserves0 = formatMoney(coinInfo?.reserves0, { decimalPlaces: 0 })
  const reserves1 = formatMoney(coinInfo?.reserves1, { decimalPlaces: 0 })
  const price = formatMoney(coinInfo?.price, { decimalPlaces: 6 })
  const usd = formatMoney(coinInfo?.usd, { decimalPlaces: 6 })
  return (
    <PoolViewContainer>
      <PoolViewItem>
        <PoolViewHeader>
          <PoolViewHeaderLeft>
            <PoolViewHeaderTitle>
              <small>$</small>
              {price}
            </PoolViewHeaderTitle>
            <PoolViewHeaderDesc>
              {token.symbol + '：' + tradeToken.symbol}&nbsp;
              <span>（1：{usd}）</span>
            </PoolViewHeaderDesc>
          </PoolViewHeaderLeft>
          <PoolViewHeaderRight></PoolViewHeaderRight>
        </PoolViewHeader>
        <PoolViewContent>
          <PoolViewContentItem>
            <p>
              <CurrencyLogo currency={token} size={'18px'} />
              <em>{token.symbol}</em>
            </p>
            <span>{reserves0}</span>
          </PoolViewContentItem>
          <PoolViewContentItem>
            <p>
              <CurrencyLogo currency={tradeToken} size={'18px'} />
              <em>{tradeToken.symbol}</em>
            </p>
            <span>{reserves1}</span>
          </PoolViewContentItem>
        </PoolViewContent>
      </PoolViewItem>
    </PoolViewContainer>
  )
}
function TradeView({ token, tradeToken }: QuotesFlexWrapProps) {
  const { t } = useTranslation()
  const { lists, loadMoreItems, hasNextPage } = useQuotesTradeListHook({ token, tradeToken })
  return (
    <QuotesTradeListWContainer>
      <QuotesTradeListUl>
        <QuotesTradeListLi>{t('Time')}</QuotesTradeListLi>
        <QuotesTradeListLi textAlign={'center'}>{t('Type')}</QuotesTradeListLi>
        <QuotesTradeListLi textAlign={'center'}>{t('Price')}($)</QuotesTradeListLi>
        <QuotesTradeListLi textAlign={'center'}>{t('Volume')}</QuotesTradeListLi>
        <QuotesTradeListLi style={{ textAlign: 'right' }}>{t('User')}</QuotesTradeListLi>
      </QuotesTradeListUl>
      <div style={{ flex: '1 1 auto', maxHeight: '500px' }}>
        <QuotesListComponent items={lists} loadMore={loadMoreItems} hasNextPage={hasNextPage}></QuotesListComponent>
      </div>
    </QuotesTradeListWContainer>
  )
}
function IntroduceView({ token, tradeToken }: QuotesFlexWrapProps) {
  const { t } = useTranslation()
  const MyToken = token as MyCurrency
  const { coinInfo } = useIntroduceViewHook({ token, tradeToken }) as { coinInfo: CoinObjectInfoDetail }
  if (!Object.keys(coinInfo).length) {
    return null
  }
  // 总供应量
  const totalValue =
    coinInfo!.usd_market_value > 0 && Number(coinInfo!.price) > 0
      ? bigNumberTransform(new BigNumber(coinInfo!.usd_market_value).dividedBy(coinInfo!.price).toFixed(6), {
          decimalPlaces: 3
        })
      : '0000'
  return (
    <>
      <IntroduceViewItem>
        <p>{t('币信息')}</p>
        <ul>
          <li>
            <IntroduceViewItemLabel>{t('主链')}</IntroduceViewItemLabel>
            <IntroduceViewItemValue>ALYX</IntroduceViewItemValue>
          </li>
          <li>
            <IntroduceViewItemLabel>{t('合约地址')}</IntroduceViewItemLabel>
            <IntroduceViewItemValue>
              {shortenAddress(MyToken?.address as string, 6)}
              &nbsp;&nbsp;
              {MyToken ? (
                <Copy isShowBox={true} toCopy={MyToken?.address as string} size={'12'}>
                  <span></span>
                </Copy>
              ) : (
                ''
              )}
            </IntroduceViewItemValue>
          </li>
          <li>
            <IntroduceViewItemLabel>{t('市值')}</IntroduceViewItemLabel>
            <IntroduceViewItemValue>
              ${formatMoney(coinInfo!.usd_market_value, { decimalPlaces: 3 })}
            </IntroduceViewItemValue>
          </li>
          <li>
            <IntroduceViewItemLabel>{t('总供应量')}</IntroduceViewItemLabel>
            <IntroduceViewItemValue>{totalValue}</IntroduceViewItemValue>
          </li>
          {/*<li>*/}
          {/*  <IntroduceViewItemLabel>{t('流通供应量')}</IntroduceViewItemLabel>*/}
          {/*  <IntroduceViewItemValue>{formatMoney(coinInfo!.price, { decimalPlaces: 6 })}</IntroduceViewItemValue>*/}
          {/*</li>*/}
          <li>
            <IntroduceViewItemLabel>{t('24小时交易量')} </IntroduceViewItemLabel>
            <IntroduceViewItemValue>
              ${formatMoney(coinInfo!.price_24h_vol, { decimalPlaces: 3 })}
            </IntroduceViewItemValue>
          </li>
        </ul>
      </IntroduceViewItem>
      <IntroduceViewItem>
        <p>{t('Tags')}</p>
        {coinInfo.tags_data &&
          coinInfo.tags_data.map(item => <IntroduceViewBtn key={item}>#&nbsp;&nbsp;{item}</IntroduceViewBtn>)}
      </IntroduceViewItem>
      <IntroduceViewItem>
        <p>
          {t('关于')}&nbsp;&nbsp;{coinInfo.name || '--'}
        </p>
        <div dangerouslySetInnerHTML={{ __html: coinInfo.intro }}></div>
      </IntroduceViewItem>
      <IntroduceViewItem>
        <p>{t('资源')}</p>
        {coinInfo.medium_data &&
          coinInfo.medium_data.map(item => {
            return (
              <a key={item.logo} className={'source'} href={item.url}>
                <img src={item.logo} />
              </a>
            )
          })}
      </IntroduceViewItem>
    </>
  )
}
export function QuotesTradeList({ token, tradeToken, coinInfo }: QuotesFlexWrapProps) {
  const { t } = useTranslation()
  const [curState, setCurState] = useState<string>('2')
  return (
    <>
      <QuotesTradeListWTitleContainer>
        <QuotesTradeListWTitle isActive={curState === '0'} onClick={() => setCurState('0')}>
          {t('资金池')}
        </QuotesTradeListWTitle>
        <QuotesTradeListWTitle isActive={curState === '1'} onClick={() => setCurState('1')}>
          {t('交易')}
        </QuotesTradeListWTitle>
        <QuotesTradeListWTitle isActive={curState === '2'} onClick={() => setCurState('2')}>
          {t('介绍')}
        </QuotesTradeListWTitle>
        <QuotesTradeListWTitle isActive={curState === '3'} onClick={() => setCurState('3')}>
          {t('数据')}
        </QuotesTradeListWTitle>
      </QuotesTradeListWTitleContainer>
      {curState === '0' ? <PoolView coinInfo={coinInfo} token={token} tradeToken={tradeToken}></PoolView> : ''}
      {curState === '1' ? <TradeView token={token} tradeToken={tradeToken}></TradeView> : ''}
      {curState === '2' ? <IntroduceView token={token} tradeToken={tradeToken}></IntroduceView> : ''}
      {curState === '3' ? <QuotesDataView token={token} tradeToken={tradeToken}></QuotesDataView> : ''}
    </>
  )
}
