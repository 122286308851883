// import { transparentize } from 'polished'
import React, { useMemo } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme
} from 'styled-components'
// import { useIsDarkMode } from '../state/user/hooks'
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 600,
  upToMedium: 960,
  upToLarge: 1280
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

export function colors(
  darkMode: boolean
): {
  advancedBG: string
  primary1: string
  bg2: string
  bg1: string
  bg4: string
  bg3: string
  bg5: string
  modalBG: string
  white: string
  primary2: string
  primary3: string
  primary4: string
  primary5: string
  primary6: string
  black: string
  yellow1: string
  yellow2: string
  text3: string
  text6: string
  border1: string
  secondary1: string
  red2: string
  red3: string
  green3: string
  text4: string
  red1: string
  text1: string
  primaryText1: string
  secondary3: string
  text2: string
  secondary2: string
  green1: string
  green2: string
  text5: string
  text7: string
  text8: string
  text9: string
  boxColor1: string
  boxColor2: string
  red4: string
  green4: string
  bg6: string
} {
  return {
    // base
    white,
    black,

    border1: darkMode ? '#252527' : '#f2f2f4',

    // text
    text1: darkMode ? '#FFFFFF' : '#000000',
    text2: darkMode ? '#C3C5CB' : '#141416',
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#C3C5CB',
    text5: darkMode ? '#2C2F36' : '#EDEEF2',
    text6: darkMode ? '#999d9e' : '#999d9e',

    text7: darkMode ? '#6d6d6f' : '#919596',
    text8: darkMode ? '#6c6c6e' : '#979797',
    text9: darkMode ? '#69696b' : '#8d8f8e',

    // backgrounds / greys
    bg1: darkMode ? '#FFFFFF' : '#FFFFFF',
    bg2: darkMode ? '#2C2F36' : '#f2f2fb',
    bg3: darkMode ? '#40444F' : '#EDEEF2',
    bg4: darkMode ? '#565A69' : '#CED0D9',
    bg5: darkMode ? '#6C7284' : '#888D9B',
    bg6: darkMode ? '#212123' : '#f8f8f8',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: darkMode ? '#2172E5' : '#0100FC',
    primary2: darkMode ? '#3680E7' : '#0100FC',
    primary3: darkMode ? '#4D8FEA' : '#0100FC',
    primary4: darkMode ? '#0100FC' : '#0100FC',
    primary5: darkMode ? '#0100FC' : '#0100FC',
    // 自由添加的
    primary6: darkMode ? '#FF6871' : '#27AE60',

    // color text
    primaryText1: darkMode ? '#fff' : '#fff',

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#0100FC',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    red1: '#FF6871',
    red2: '#F82D3A',
    red3: '#f26463',
    red4: 'rgba(242,100,99,0.8)',
    green1: '#27AE60',
    green2: '#50d0ad',
    green3: '#22c79d',
    green4: 'rgba(34,199,157,0.8)',
    yellow1: '#FFE270',
    yellow2: '#F3841E',

    boxColor1: darkMode
      ? '0px 0px 1px rgba(255, 255, 255, 0.01), 0px 4px 8px rgba(255, 255, 255, 0.04), 0px 16px 24px rgba(255, 255, 255, 0.04),\n    0px 24px 32px rgba(255, 255, 255, 0.01)'
      : '0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),\n    0px 24px 32px rgba(0, 0, 0, 0.01)',
    boxColor2: darkMode ? 'rgba(255, 255, 255, 0.04)' : 'rgba(0, 0, 0, 0.04)'

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  // const darkMode =
  const darkMode = false

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  }
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.018em;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Inter var', sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  /* background-color: ${({ theme }) => theme.bg2}; */
  background-color: ${({ theme }) => theme.bg2};
}

body {
  /* min-height: 100vh; */
  /* background-position: 0 -30vh;
  background-repeat: no-repeat; */

}
`
//   // background-image: ${({ theme }) =>
//   //   `radial-gradient(50% 50% at 50% 50%, rgba(82 236 150 / 50%) 0%, ${transparentize(1, theme.bg1)} 100%)`};
