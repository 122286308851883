import React from 'react'
import styled from 'styled-components'
// ButtonError, ButtonLight,
import { FixedButtonSecondary } from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { useQuotesState } from '../../state/quotes/hook'
const BtnWrap = styled.div<{ zIndex?: number }>`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 7px 15px;
  z-index: 70;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  max-width: 1200px;
  justify-content: flex-end;
  align-items: center;
  display: ${({ zIndex }) => (zIndex && zIndex >= 70 ? 'flex' : 'none')};
  @media (max-width: 721px) {
    justify-content: center;
  }
`
const BtnItem = styled.div`
  flex: 1;
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
  max-width: 165px;
`
export default function QuotesButton({ onBuy }: { onBuy: (type?: string) => void }) {
  const { t } = useTranslation()
  const { zIndex } = useQuotesState()
  return (
    <BtnWrap zIndex={zIndex}>
      <BtnItem>
        <FixedButtonSecondary
          onClick={() => {
            onBuy('buy')
          }}
          isBuy={true}
        >
          {t('Buy')}
        </FixedButtonSecondary>
      </BtnItem>
      <BtnItem>
        <FixedButtonSecondary
          onClick={() => {
            onBuy('sell')
          }}
        >
          {t('Sell')}
        </FixedButtonSecondary>
      </BtnItem>
    </BtnWrap>
  )
}
