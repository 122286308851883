import React, { createContext, forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react'
import { createChart, IChartApi, Time } from 'lightweight-charts'
import { formTimeStamp2DateTime4 } from '../../utils/common'
import { useDarkModeManager } from '../../state/user/hooks'

//@ts-ignore
export const Context = createContext()
const ChartContainer = forwardRef((props: any, ref) => {
  const [darkMode] = useDarkModeManager()
  const { children, height, watermark, container, layout, ...rest } = props
  const chartApiRef = useRef<{ _api?: IChartApi; api: () => IChartApi; free: () => void }>({
    api() {
      if (!this._api) {
        this._api = createChart(container, {
          watermark: watermark || {},
          width: container.clientWidth,
          height: height ? height : 350,
          localization: {
            locale: 'en',
            dateFormat: 'yy/MM/dd'
          },
          layout: {
            background: {
              type: 'solid',
              color: 'transparent'
            }
          },
          handleScale: false,
          handleScroll: false,
          kineticScroll: {
            mouse: false,
            touch: false
          },
          leftPriceScale: {
            visible: true,
            ticksVisible: true,
            borderVisible: false,
            entireTextOnly: false,
            width: 0
          },
          crosshair: {
            vertLine: {
              visible: false,
              labelVisible: false
            },
            horzLine: {
              visible: false,
              labelVisible: false
            }
          },
          grid: {
            vertLines: {
              visible: false
            }
          },
          rightPriceScale: {
            visible: false,
            borderVisible: false
          },
          timeScale: {
            rightBarStaysOnScroll: false,
            shiftVisibleRangeOnNewBar: true,
            lockVisibleTimeRangeOnResize: true,
            secondsVisible: true,
            borderVisible: false,
            timeVisible: true,
            ticksVisible: true,
            tickMarkFormatter: (time: Time) => {
              return formTimeStamp2DateTime4(time as number)
            }
          },
          ...rest
        })
        this._api.timeScale().fitContent()
      }
      return this._api
    },
    free() {
      this._api && this._api.remove()
    }
  })

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current
    const chart = currentRef.api()
    const handleResize = () => {
      chart.applyOptions({
        ...rest,
        width: container.clientWidth
      })
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      chart && chart.remove()
    }
  }, [])

  useEffect(() => {
    const currentRef = chartApiRef.current
    currentRef.api().applyOptions({
      grid: {
        horzLines: {
          color: darkMode ? 'rgba(195,188,219,0.1)' : '#f2f2f2'
        }
      },
      layout: {
        textColor: darkMode ? '#737375' : '#959595'
      },
      leftPriceScale: {
        textColor: darkMode ? '#717173' : '#949494'
      }
    })
  }, [darkMode])

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current
    currentRef.api().applyOptions(rest)
  }, [])

  useImperativeHandle(ref, () => chartApiRef.current.api(), [])

  return <Context.Provider value={chartApiRef.current}>{children}</Context.Provider>
})

export default ChartContainer
