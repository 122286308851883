import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'

i18next
  .use(XHR)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `./locales/{{lng}}.json`
    },
    react: {
      useSuspense: true
    },
    fallbackLng: localStorage.getItem('userLang') || 'en',
    preload: ['zh'],
    keySeparator: false,
    interpolation: { escapeValue: false }
  })

export default i18next
