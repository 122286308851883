import React, { useEffect } from 'react'
import { useCallback, useRef, useState } from 'react'
import LineChartContainer from './LineChartContainer'
import LineSeries from './LineSeries'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { LatestTradeAddressListItem } from '../../api/swap/klineData'
import { Time } from 'lightweight-charts'
import { formatMoney } from '../../utils/common'
const QuotesDataLegend = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 15px;
`
const QuotesDataLegendItem = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  font-size: 10px;
  color: ${({ theme }) => theme.text9};
  padding-left: 10px;
  &::after {
    display: block;
    position: absolute;
    content: '';
    background-color: ${({ color }) => color};
    border-radius: 50%;
    overflow: hidden;
    width: 7px;
    height: 7px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 2px ${({ color }) => color};
  }
  em {
    font-size: 10px;
    font-style: normal;
    color: ${({ theme }) => theme.text1};
  }
`

function formatLineData(
  data: LatestTradeAddressListItem[]
): {
  buy: { time: Time; value: number }[]
  sell: { time: Time; value: number }[]
} {
  const buy: { time: Time; value: number }[] = []
  const sell: { time: Time; value: number }[] = []
  if (!data || !data.length) {
    return {
      buy,
      sell
    }
  }
  for (const item of data) {
    buy.push({
      time: item.time as Time,
      value: item.buy_num
    })
    sell.push({
      time: item.time as Time,
      value: item.sell_num
    })
  }
  return {
    buy,
    sell
  }
}
export default function LineChart({ data }: { data: LatestTradeAddressListItem[] }) {
  const { t } = useTranslation()
  const [params, setParams] = useState({
    buy: '0000',
    sell: '0000'
  })
  const [container, setContainer] = useState(false)
  const handleRef = useCallback(ref => setContainer(ref), [])
  const ParentChart = useRef<any>(null)
  const SellChart = useRef<any>(null)
  const BuyChart = useRef<any>(null)
  useEffect(() => {
    if (!data.length) {
      return
    }
    const { buy, sell } = formatLineData(data)
    BuyChart.current && BuyChart.current.setData(buy)
    SellChart.current && SellChart.current.setData(sell)
    const buyTotal = buy.reduce((index, item) => {
      return index + item.value
    }, 0)
    const sellTotal = sell.reduce((index, item) => {
      return index + item.value
    }, 0)
    setParams({
      buy: formatMoney(buyTotal, { decimalPlaces: 2 }),
      sell: formatMoney(sellTotal, { decimalPlaces: 2 })
    })
    const visibleRange = ParentChart.current && ParentChart.current.timeScale().getVisibleRange()
    ParentChart.current && ParentChart.current.timeScale().setVisibleRange(visibleRange)
  }, [data])
  return (
    <div style={{ paddingRight: '15px' }}>
      <QuotesDataLegend>
        <QuotesDataLegendItem color={'#5bcba5'}>
          {t('买入地址数')}:&nbsp;<em>{params.buy}</em>
        </QuotesDataLegendItem>
        <QuotesDataLegendItem color={'#e66b64'}>
          {t('卖出地址数')}:&nbsp;<em>{params.sell}</em>
        </QuotesDataLegendItem>
      </QuotesDataLegend>
      <div ref={handleRef}>
        {container && (
          <LineChartContainer ref={ParentChart} height={210} container={container}>
            <LineSeries
              priceFormat={{
                type: 'volume',
                precision: 1,
                minMove: 0.1
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue = 0
                }
                return res
              }}
              color={'#5bcba5'}
              ref={BuyChart}
            ></LineSeries>
            <LineSeries
              priceFormat={{
                type: 'volume',
                precision: 1,
                minMove: 0.1
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue = 0
                }
                return res
              }}
              color={'#e66b64'}
              ref={SellChart}
            ></LineSeries>
          </LineChartContainer>
        )}
      </div>
    </div>
  )
}
