import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  /* max-width: 700px; */
  width: 700px;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 42px 50px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-radius: 12px;
    width: 90% !important;
    margin: 0 auto;
    padding: 20px 20px;
  `};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, style = {} }: { children: React.ReactNode; style?: any }) {
  return <BodyWrapper style={style}>{children}</BodyWrapper>
}
