// @ts-nocheck
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { NavLink, Link as HistoryLink, useHistory } from 'react-router-dom'
import { MyCurrency } from '../../components/KLineChart/hook'
import { ArrowLeft, BarChart2 } from 'react-feather'
import { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'
import { Currency } from '@uniswap/sdk'
import SwapTipsModal from '../swap/SwapTipsModal'
import { judgeTokenHasMarketData } from '../../api/swap/klineData'
import { useActiveWeb3React } from '../../hooks'
const swapVersion = process.env.REACT_APP_SWAP_VERSION_V1!
const Tabs = styled.div`
  border-radius: 3rem;
  display: flex;
  flex-direction: coulmn;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  /* ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center; */
  /* height: 3rem; */
  /* border-radius: 3rem; */
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  font-size: 20px;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: #000;
  }

  /* :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  } */
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

const TabsIconDiv = styled(BarChart2)<{ ismyactive: string }>`
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  width: 50px;
  height: 32px;
  cursor: pointer;
  color: ${({ theme, ismyactive }) => {
    return ismyactive === 'true' ? theme.yellow2 : theme.text1
  }};
`
const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const TitleDesc = styled.div`
  color: #89898a;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-top: 24px;
  padding-bottom: 15px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 12px;
  `}
`

export function SwapPoolTabs({
  active,
  isMyActive,
  showChartBar,
  token,
  tradeToken
}: {
  active: 'swap' | 'pool'
  toggleShowBar?: Function
  isMyActive?: boolean
  showChartBar?: boolean
  token?: Currency
  tradeToken?: Currency
}) {
  const history = useHistory()
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const MyToken = token as MyCurrency
  const TradeToken = tradeToken as MyCurrency
  const SwapTipsModalRef = useRef<any>(null)
  const fetchIsMarketData = useCallback(async () => {
    try {
      const MyToken = token as MyCurrency
      const TradeToken = tradeToken as MyCurrency
      const linkUrl = `/quotes/${MyToken && MyToken.address}/${TradeToken && TradeToken.address}`
      const result = await judgeTokenHasMarketData({
        token: (MyToken && MyToken.address) as string,
        tradeToken: TradeToken && (TradeToken.address as string),
        swapVersion,
        chainId
      })
      if (result === 1) {
        history.replace(linkUrl)
        return
      }
      SwapTipsModalRef.current.toggleWalletModal(true)
    } catch (e) {
      console.log(e)
    }
  }, [token, tradeToken, chainId])
  return (
    <TabsContainer>
      <Tabs>
        {active === 'swap' && (
          <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
            {t('swap')}
          </StyledNavLink>
        )}
        {active === 'pool' && (
          <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
            {t("Fluidity")}
          </StyledNavLink>
        )}

        {/* <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
          {t('pool')}
        </StyledNavLink> */}
        {/* <ExternalLink href="https://Bidge.dongleswap.com" style={{ fontSize: '20px',color:'#45a4ab',fontWeight: 0}}>
        {t('crossChainBridge')}
      </ExternalLink>*/}
      </Tabs>
      {active === 'swap' && <TitleDesc>{t("Instantly trade tokens")}</TitleDesc>}
      {active === 'pool' && <TitleDesc>{t("Add liquidity and get rewards")}</TitleDesc>}
      {/* {showChartBar && MyToken && MyToken.address && TradeToken && TradeToken.address ? (
        <>
          <TabsIconDiv onClick={fetchIsMarketData} ismyactive={JSON.stringify(isMyActive)}></TabsIconDiv>
          <SwapTipsModal ref={SwapTipsModalRef}></SwapTipsModal>
        </>
      ) : (
        ''
      )} */}
    </TabsContainer>
  )
}

export function FindPoolTabs() {
  const { t } = useTranslation()
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{t('Import Pool')}</ActiveText>
        <QuestionHelper text={t('importPoolHelpTips')} />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  const { t } = useTranslation()
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{adding ? t("Add Liquidity") : t("Remove Liquidity")}</ActiveText>
        <QuestionHelper text={adding ? t('addLiquidityHelpTips') : t('removeLiquidityHelpTip')} />
      </RowBetween>
    </Tabs>
  )
}
