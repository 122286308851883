import { AppDispatch, AppState } from '../index'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { setZIndex } from './actions'

export function useQuotesState(): AppState['quotes'] {
  return useSelector<AppState, AppState['quotes']>(state => state.quotes)
}
export function useQuotesActionHandlers(): {
  onChangeZIndex: (zIndex: number) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onChangeZIndex = useCallback(
    (zIndex: number) => {
      dispatch(setZIndex({ zIndex }))
    },
    [dispatch]
  )
  return {
    onChangeZIndex
  }
}
