// @ts-nocheck
import React, { useContext, useRef } from 'react'
import { darken } from 'polished'
import { Chrome, Home } from 'react-feather'
import styled, { ThemeContext } from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import useToggle from '../../hooks/useToggle'

import i18next from 'i18next'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../state'
import { updateUserLang } from '../../state/user/actions'

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  /* background-color: ${({ theme }) => theme.bg3}; */

  color: #212429;
  font-size: 0.7rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    /* background-color: ${({ theme }) => darken(0.05, theme.bg3)}; */
  }

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 5.125rem;
  background-color: ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0rem;
  z-index: 100;
`

const MenuItem = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text1};
  :hover {
    color: ${({ theme }) => '#0100FC'};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`
// function switchCnLanuage() {
//   // const disaptch=use
//   i18next.changeLanguage('zh')
// }

// function switchEnLanuage() {
//   i18next.changeLanguage('en')
// }

export default function Language() {
  const dispatch = useDispatch<AppDispatch>()
  const node = useRef<HTMLDivElement>()
  const [open, toggle] = useToggle(false)
  useOnClickOutside(node, open ? toggle : undefined)
  // const theme = useContext(ThemeContext)
  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 0C4.476 0 0 4.476 0 10C0 15.524 4.476 20 10 20C15.524 20 20 15.524 20 10C20 4.476 15.524 0 10 0ZM18.6187 9.33333H15.9773C15.8627 6.44667 15.02 3.896 13.7453 2.19333C16.4613 3.49867 18.3747 6.18 18.6187 9.33333ZM14.6333 9.33333H10.6667V1.432C12.8093 2.00267 14.4653 5.27733 14.6333 9.33333ZM9.33333 1.432V9.33333H5.36667C5.53467 5.27733 7.19067 2.00267 9.33333 1.432ZM6.256 2.19333C4.98 3.896 4.13867 6.44667 4.024 9.33333H1.38133C1.62533 6.18 3.53867 3.49867 6.256 2.19333ZM1.38133 10.6667H4.02267C4.13733 13.5533 4.98 16.104 6.25467 17.8067C3.53867 16.5013 1.62533 13.82 1.38133 10.6667ZM5.36667 10.6667H9.33333V18.568C7.19067 17.9973 5.53467 14.7227 5.36667 10.6667ZM10.6667 18.568V10.6667H14.6333C14.4653 14.7227 12.8093 17.9973 10.6667 18.568ZM13.744 17.8067C15.02 16.104 15.8613 13.5533 15.976 10.6667H18.6173C18.3747 13.82 16.4613 16.5013 13.744 17.8067Z"
            fill="black"
          />
        </svg>
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          <MenuItem
            onClick={() => {
              dispatch(updateUserLang({ userLang: 'zh' }))
              i18next.changeLanguage('zh')
              localStorage.setItem('userLang', 'zh')
            }}
          >
            {/* <Chrome size={14} /> */}
            CN
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(updateUserLang({ userLang: 'en' }))
              i18next.changeLanguage('en')
              localStorage.setItem('userLang', 'en')
            }}
          >
            {/* <Home size={14} /> */}
            EN
          </MenuItem>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
